import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Leiter } from 'src/entities/leiter';
import { Person } from 'src/entities/person';
import { LeiterComponent } from '../leiter/leiter.component';

@Component({
  selector: 'app-pony',
  templateUrl: './pony.component.html',
  styleUrls: ['./pony.component.css']
})
export class PonyComponent extends LeiterComponent implements OnInit {

  constructor(
    _api: ApiService,
    loadingService: LoadingService,
    public modalController: ModalController,
    public alertController: AlertController,
    public popover:PopoverController) {
    super(_api, loadingService, modalController, alertController, popover);
    this.pony = true;
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {ponyComponent: this}
    });
    await popover.present();
  }
}
