import { Anwesenheit } from "./anwesenheit";
import { Person } from "./person";

export class Leiter {
    private _id?: string;
    private _person?: Person;
    private _pony?: boolean;
    private _signedOff?: boolean;
    private _active?: boolean;
    private _memberOfHonour?: boolean;
    private _contributionPaid?: boolean;
    private _courseCompleted?: boolean;
    private _anwesenheit?: Anwesenheit;
    private _messdienerGruppe?: string;
    private _ausschuss?: string;

    constructor(
        id?: string,
        person?: Person,
        pony?: boolean,
        signedOff?: boolean,
        active?: boolean,
        memberOfHonour?: boolean,
        contributionPaid?: boolean,
        courseCompleted?: boolean,
        anwesenheit?: Anwesenheit,
        messdienerGruppe?: string,
        ausschuss?: string) {
            this._id = id;
            this._person = person;
            this._pony = pony;
            this._signedOff = signedOff;
            this._active = active;
            this._memberOfHonour = memberOfHonour;
            this._contributionPaid = contributionPaid;
            this._courseCompleted = courseCompleted;
            this._anwesenheit = anwesenheit;
            this._messdienerGruppe = messdienerGruppe;
            this._ausschuss = ausschuss;
         }

    public get id() {
        return this._id!;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get person() {
        return this._person!;
    }

    public set person(person: Person) {
        this._person = person;
    }

    public get pony() {
        return this._pony!;
    }

    public set pony(pony: boolean) {
        this._pony = pony;
    }

    public get signedOff() {
        return this._signedOff!;
    }

    public set signedOff(signedOff: boolean) {
        this._signedOff = signedOff;
    }

    public get active() {
        return this._active!;
    }

    public set active(active: boolean) {
        this._active = active;
    }

    public get memberOfHonour() {
        return this._memberOfHonour!;
    }

    public set memberOfHonour(memberOfHonour: boolean) {
        this._memberOfHonour = memberOfHonour;
    }

    public get contributionPaid() {
        return this._contributionPaid!;
    }

    public set contributionPaid(contributionPaid: boolean) {
        this._contributionPaid = contributionPaid;
    }

    public get courseCompleted() {
        return this._courseCompleted!;
    }

    public set courseCompleted(courseCompleted: boolean) {
        this._courseCompleted = courseCompleted;
    }

    public get anwesenheit() {
        return this._anwesenheit!;
    }

    public set anwesenheit(anwesenheit: Anwesenheit) {
        this._anwesenheit = anwesenheit;
    }

    public get messdienerGruppe() {
        return this._messdienerGruppe!;
    }

    public set messdienerGruppe(messdienerGruppe: string) {
        this._messdienerGruppe = messdienerGruppe;
    }
    
    public get ausschuss() {
        return this._ausschuss!;
    }

    public set ausschuss(ausschuss: string) {
        this._ausschuss = ausschuss;
    }
}