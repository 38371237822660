import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Messdiener } from 'src/entities/messdiener';
import { MessdienerComponent } from '../messdiener/messdiener.component';
import { DatePipe } from '@angular/common'
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-messdiener-detail',
  templateUrl: './messdiener-detail.component.html',
  styleUrls: ['./messdiener-detail.component.scss'],
})
export class MessdienerDetailComponent implements OnInit {

  @Input() messdienerComponent: MessdienerComponent;
  mobile: Boolean;

  strichDates: any[] = [];
  strichAlert: any;

  constructor(
    public modalController: ModalController,
    public alertController: AlertController,
    public datepipe: DatePipe,
    private popover:PopoverController,
    public _auth: AuthService) { }

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  @HostListener('window:popstate', ['$event'])
  dismissDeleteAlert() {
    if(this.strichAlert != null){
      this.strichAlert.dismiss();
    }
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  deleteSelectedMessdiener() {
    this.messdienerComponent.presentDeleteAlert();
  }

  saveSelectedMessdiener(messdiener: Messdiener) {
    this.messdienerComponent.saveMessdiener(this.strichDates);
    this.modalController.dismiss(true);
  }

  incrementStriche(){
    if(this.messdienerComponent.selectedMessdiener.striche < 50){
      this.presentStrichAlert();
    }
  }

  async presentStrichAlert() {
    const now = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    const pastDate = new Date();
    pastDate.setFullYear(pastDate.getFullYear() - 2);
    let pastDateString = this.datepipe.transform(pastDate, 'yyyy-MM-dd');
    this.messdienerComponent.otherAlertIsOpen = true;
    this.strichAlert = await this.alertController.create({
      header: 'Strich hinzufügen',
      message: "Wann war " + this.messdienerComponent.selectedMessdiener.person.firstname + " " + this.messdienerComponent.selectedMessdiener.person.lastname + " nicht anwesend?",
      inputs:[
        // input date with min & max
        {
          name: 'date',
          type: 'date',
          min: pastDateString,
          max: now,
          value: now
        },
      ],
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Hinzufügen',
          handler: data => {
            this.messdienerComponent.selectedMessdiener.striche++;
            this.strichDates.push(data);
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'add strich'
    };
    history.pushState(modalState, null);

    this.strichAlert.onDidDismiss().then(() => {this.messdienerComponent.otherAlertIsOpen = false});
    await this.strichAlert.present();
  }

  presentpromoteMessdienerAlert(){
    this.messdienerComponent.promoteMessdiener();
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {messdienerDetailComponent: this}
    });
    await popover.present();
  }
}
