import { Messdiener } from "./messdiener";

export class Messdienerveranstaltung {
    private _id?: string;
    private _messdiener?: Messdiener[];
    private _name?: string;
    private _startDate?: Date;

    constructor(
        id?: string,
        messdiener?: Messdiener[],
        name?: string,
        startDate?: Date
        ) {
            this._id = id;
            this._messdiener = messdiener;
            this._name = name;
            this._startDate = startDate;
         }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get messdiener() {
        return this._messdiener;
    }

    public set messdiener(messdiener: Messdiener[]) {
        this._messdiener = messdiener;
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get startDate() {
        return this._startDate;
    }

    public set startDate(startDate: Date) {
        this._startDate = startDate;
    }
}