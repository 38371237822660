import { Leiter } from "./leiter";

interface Month {
    name: string;
    number: number;
  }

export class Leiterrunde {
    public static months: { name: string, number: number }[] = [
        { name: "Januar", number: 1 },
        { name: "Februar", number: 2 },
        { name: "März", number: 3 },
        { name: "April", number: 4 },
        { name: "Mai", number: 5 },
        { name: "Juni", number: 6 },
        { name: "Juli", number: 7 },
        { name: "August", number: 8 },
        { name: "September", number: 9 },
        { name: "Oktober", number: 10 },
        { name: "November", number: 11 },
        { name: "Dezember", number: 12 }
    ];

    constructor(
        private _id: string,
        private _leiter: Leiter[],
        private _month: Month,
        private _year: number) { }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get leiter() {
        return this._leiter;
    }

    public set leiter(leiter: Leiter[]) {
        this._leiter = leiter;
    }

    public get month() {
        return this._month;
    }

    public set month(month: Month) {
        this._month = month;
    }

    public get year() {
        return this._year;
    }

    public set year(year: number) {
        this._year = year;
    }
}