import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Leiter } from 'src/entities/leiter';
import { Messdiener } from 'src/entities/messdiener';
import { MessdienerGruppeDetailComponent } from '../messdiener-gruppe-detail/messdiener-gruppe-detail.component';

@Component({
  selector: 'app-messdiener-gruppe-detail-add',
  templateUrl: './messdiener-gruppe-detail-add.component.html',
  styleUrls: ['./messdiener-gruppe-detail-add.component.scss'],
})
export class MessdienerGruppeDetailAddComponent implements OnInit {

  @Input() messdienergruppeDetailComponent: MessdienerGruppeDetailComponent;
  @Input() isLeiter: boolean;
  mobile: Boolean;

  leiters: Leiter[] = [];
  messdieners: Messdiener[] = [];
  selectedId = null;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
    if(this.isLeiter){
      this.leiters= this.messdienergruppeDetailComponent.messdienergruppeComponent.activeLeiters
      .filter(leiter => !this.messdienergruppeDetailComponent.messdienergruppeComponent.selectedMessdienergruppe.leiter.some(l => l.id == leiter.id))
    }else{
      this.messdieners= this.messdienergruppeDetailComponent.messdienergruppeComponent.activeMessdieners
      .filter(messdiener => !this.messdienergruppeDetailComponent.messdienergruppeComponent.selectedMessdienergruppe.messdiener.some(l => l.id == messdiener.id))
    }
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  onValueChange(event: any){
    this.selectedId = event.detail.value;
  }

  addLeiter(){
    this.messdienergruppeDetailComponent.addLeiterToMessdienerGruppe(this.selectedId);
    this.modalController.dismiss();
  }

  addMessdiener(){
    this.messdienergruppeDetailComponent.addMessdienerToMessdienerGruppe(this.selectedId);
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (window.history.state.modal && !this.messdienergruppeDetailComponent.messdienergruppeComponent.otherModalIsNull) {
      history.back();
    }
  }
}
