import { Leiter } from "./leiter";

export class Ausschuss {

    private _id?: string;
    private _leiter?: Leiter[];
    private _name?: string;
    private _active?: boolean;

    constructor(
        id?: string,
        leiter?: Leiter[],
        name?: string,
        active?: boolean) {
            this._id = id;
            this._leiter = leiter;
            this._name = name;
            this._active = active;
         }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get leiter() {
        return this._leiter;
    }

    public set leiter(leiter: Leiter[]) {
        this._leiter = leiter;
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get active() {
        return this._active;
    }

    public set active(active: boolean) {
        this._active = active;
    }
}