import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Ausschuss } from 'src/entities/ausschuss';
import { Leiter } from 'src/entities/leiter';
import { Person } from 'src/entities/person';
import { AusschussDetailAddComponent } from '../ausschuss-detail-add/ausschuss-detail-add.component';
import { AusschussComponent } from '../ausschuss/ausschuss.component';

@Component({
  selector: 'app-ausschuss-detail',
  templateUrl: './ausschuss-detail.component.html',
  styleUrls: ['./ausschuss-detail.component.scss'],
})
export class AusschussDetailComponent implements OnInit {

  @Input() ausschussComponent: AusschussComponent;
  @ViewChild('leiterAddButton') leiterAddButton;
  @ViewChild('leiterTable') leiterTable!: MatTable<Leiter>;
  mobile: Boolean;

  addModal: any;
  deleteAlert: any;

  leiterDataSource = new MatTableDataSource<Leiter>();
  leiterColumnsToDisplay = ['leiter.firstname', 'leiter.lastname', 'leiter.delete'];

  displayDeleteButton: true;

  constructor(
    public modalController: ModalController,
    private _api: ApiService,
    private alertController: AlertController,
    public loadingService: LoadingService,
    private popover: PopoverController,
    public _auth: AuthService
  ) { }

  ngOnInit() {
    this.getAusschussDetails();
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  getAusschussDetails() {
    this.loadingService.present();
    this.ausschussComponent.selectedAusschuss.leiter = [];
    this._api.getTypeRequest('ausschuss/' + this.ausschussComponent.selectedAusschuss.id, "")?.subscribe((res: any) => {
      if (res.status) {
        const element = res.data;
        for (let index = 0; index < element.leiter.length; index++) {
          let person: Person = new Person(element.leiter[index].person.id, element.leiter[index].person.firstname, element.leiter[index].person.lastname);
          let leiter: Leiter = new Leiter(element.leiter[index].id, person);
          this.ausschussComponent.selectedAusschuss.leiter.push(leiter);
        }
        this.leiterDataSource.data = this.ausschussComponent.selectedAusschuss.leiter;
        this.loadingService.dismiss();
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }

  addLeiterToAusschuss(leiterID: string) {
    var body = {
      "ausschussId": this.ausschussComponent.selectedAusschuss.id,
      "leiterId": leiterID
    };
    this.loadingService.present();
    this._api.postTypeRequest('ausschuss/leiter/add', body)?.subscribe((res: any) => {
      var leiter = this.ausschussComponent.activeLeiters.find(leiter => leiter.id == leiterID);
      this.ausschussComponent.selectedAusschuss.leiter.push(leiter);
      this.leiterTable.renderRows();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  deleteSelectedAusschuss() {
    this.ausschussComponent.presentDeleteAlert();
  }

  saveSelectedAusschuss(ausschuss: Ausschuss) {
    this.ausschussComponent.saveAusschuss(ausschuss);
    this.modalController.dismiss(true);
  }

  async presentAddModal() {
    this.leiterAddButton._elementRef.nativeElement.blur();
    this.ausschussComponent.otherModalIsNull = false;
    this.addModal = await this.modalController.create({
      component: AusschussDetailAddComponent,
      componentProps: {
        'ausschussDetailComponent': this
      }
    });
    await this.addModal.present();
  }

  async presentLeiterDeleteAlert(leiter: Leiter) {
    this.ausschussComponent.otherModalIsNull = false;
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + leiter.person.firstname + " " + leiter.person.lastname + "  von dem Ausschuss " + this.ausschussComponent.selectedAusschuss.name + " entfernen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Entfernen',
          handler: () => {
            this.loadingService.present();
            var body = {
              "ausschussId": this.ausschussComponent.selectedAusschuss.id,
              "leiterId": leiter.id
            };
            this._api.postTypeRequest('ausschuss/leiter/delete', body)?.subscribe((res: any) => {
              const index = this.ausschussComponent.selectedAusschuss.leiter.indexOf(leiter, 0);
              if (index > -1) {
                this.ausschussComponent.selectedAusschuss.leiter.splice(index, 1);
              }
              this.leiterTable.renderRows();
              this.loadingService.dismiss();
            }, err => {
              console.log(err);
              this.loadingService.dismiss();
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  @HostListener('window:popstate', ['$event'])
  dismissAddAlert() {
    if (this.addModal != null) {
      this.addModal.dismiss();
      this.addModal = null;
      this.ausschussComponent.otherModalIsNull = true;
    }
    if (this.deleteAlert != null) {
      this.deleteAlert.dismiss();
      this.deleteAlert = null;
      this.ausschussComponent.otherModalIsNull = true;
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: { ausschussDetailComponent: this }
    });
    await popover.present();
  }
}
