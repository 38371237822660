import { Leiter } from "./leiter";
import { Messdiener } from "./messdiener";

export class Messdienergruppe {

    private _id?: string;
    private _leiter?: Leiter[];
    private _messdiener?: Messdiener[];
    private _name?: string;
    private _year?: number;
    private _active?: boolean;

    constructor(
        id?: string,
        leiter?: Leiter[],
        messdiener?: Messdiener[],
        name?: string,
        year?: number,
        active?: boolean) {
            this._id = id;
            this._leiter = leiter;
            this._messdiener = messdiener;
            this._name = name;
            this._year = year;
            this._active = active;
         }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get leiter() {
        return this._leiter;
    }

    public set leiter(leiter: Leiter[]) {
        this._leiter = leiter;
    }

    public get messdiener() {
        return this._messdiener;
    }

    public set messdiener(messdiener: Messdiener[]) {
        this._messdiener = messdiener;
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get year() {
        return this._year;
    }

    public set year(year: number) {
        this._year = year;
    }

    public get active() {
        return this._active;
    }

    public set active(active: boolean) {
        this._active = active;
    }
}