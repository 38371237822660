import { Leiter } from "./leiter";

export class Leiterveranstaltung {
    private _id?: string;
    private _leiter?: Leiter[];
    private _name?: string;
    private _startDate?: Date;
    private _mandatory?: boolean;

    constructor(
        id?: string,
        leiter?: Leiter[],
        name?: string,
        startDate?: Date,
        mandatory?: boolean
        ) {
            this._id = id;
            this._leiter = leiter;
            this._name = name;
            this._startDate = startDate;
            this._mandatory = mandatory;
         }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get leiter() {
        return this._leiter;
    }

    public set leiter(leiter: Leiter[]) {
        this._leiter = leiter;
    }

    public get name() {
        return this._name;
    }

    public set name(name: string) {
        this._name = name;
    }

    public get startDate() {
        return this._startDate;
    }

    public set startDate(startDate: Date) {
        this._startDate = startDate;
    }

    public get mandatory() {
        return this._mandatory;
    }

    public set mandatory(mandatory: boolean) {
        this._mandatory = mandatory;
    }
}