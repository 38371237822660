import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Leiter } from 'src/entities/leiter';
import { AusschussDetailComponent } from '../ausschuss-detail/ausschuss-detail.component';

@Component({
  selector: 'app-ausschuss-detail-add',
  templateUrl: './ausschuss-detail-add.component.html',
  styleUrls: ['./ausschuss-detail-add.component.scss'],
})
export class AusschussDetailAddComponent implements OnInit {

  @Input() ausschussDetailComponent: AusschussDetailComponent;
  mobile: Boolean;

  leiters: Leiter[] = [];
  selectedId = null;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
    this.leiters= this.ausschussDetailComponent.ausschussComponent.activeLeiters
    .filter(leiter => !this.ausschussDetailComponent.ausschussComponent.selectedAusschuss.leiter.some(l => l.id == leiter.id))
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  onValueChange(event: any){
    this.selectedId = event.detail.value;
  }

  addLeiter(){
    this.ausschussDetailComponent.addLeiterToAusschuss(this.selectedId);
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (window.history.state.modal && !this.ausschussDetailComponent.ausschussComponent.otherModalIsNull) {
      history.back();
    }
  }

}
