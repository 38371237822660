import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Leiter } from 'src/entities/leiter';
import { Person } from 'src/entities/person';
import { ApiService } from './../../../services/api.service'
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { LeiterDetailsPage } from 'src/app/leiter-details/leiter-details.page';
import { parseMessage } from '@angular/localize/src/utils';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';

@Component({
  selector: 'app-leiter',
  templateUrl: './leiter.component.html',
  styleUrls: ['./leiter.component.css']
})
export class LeiterComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<Leiter>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('content') private content: any;
  @ViewChild('addButton') addButton;
  ausschussCheckboxChecked: Boolean = false;
  messdienerGruppeCheckboxChecked: Boolean = false;
  signedOffCheckboxChecked: Boolean = false;
  activeCheckboxChecked: Boolean = true;
  contributionCheckboxChecked: Boolean = false;
  courseCheckboxChecked: Boolean = false;
  ausschussToggle: Boolean = false;
  messdienerGruppeToggle: Boolean = false;
  signedOffToggle: Boolean = false;
  activeToggle: Boolean = true;
  contributionToggle: Boolean = false;
  courseToggle: Boolean = false;
  pony = false;
  memberOfHonour = false;
  dataSource = new MatTableDataSource<Leiter>();
  columnsToDisplay = ['person.firstname', 'person.lastname'];
  leiters: Leiter[] = [];
  selectedLeiter!: Leiter;
  backupLeiter: Leiter = new Leiter();
  displayDeleteButton: boolean = true;
  filterIcon: Boolean = true;

  leiterCount: number = 100;
  currentPageSize: number = 5;
  currentPageIndex: number = 0;
  sortProperty: string = "person.lastname";
  sortDirection: string = "asc";
  searchInput = "";

  detailModal: any;
  deleteAlert: any;

  constructor(
    private _api: ApiService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    public alertController: AlertController,
    public popover:PopoverController) { }

  async presentModal() {
    this.detailModal = await this.modalController.create({
      component: LeiterDetailsPage,
      componentProps: {
        'leiterComponent': this
      }
    });
    await this.detailModal.present()
    await this.detailModal.onDidDismiss().then((r) => {
      if (!r.data) {
        this.restoreLeiter();
      }
      this.displayDeleteButton = true;
    });
  }

  async presentDeleteAlert() {
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + this.selectedLeiter.person.firstname + " " + this.selectedLeiter.person.lastname + " löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Löschen',
          handler: () => {
            this.loadingService.present();
            this._api.deleteTypeRequest('leiter/' + this.selectedLeiter.id)?.subscribe((res: any) => {
              this.getLeiters();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  @HostListener('window:popstate', ['$event'])
  dismissDeleteAlert() {
    if (this.detailModal != null) {
      if (this.deleteAlert != null) {
        this.deleteAlert.dismiss();
        this.deleteAlert = null;
      } else {
        this.detailModal.dismiss();
        this.detailModal = null;
      }
    }
  }

  ngOnInit(): void {
    var person = new Person();
    this.backupLeiter.person = person;
    this.loadingService.present();
    this.getLeiters();
    this.loadingService.dismiss();
  }


  public getServerData(event?: PageEvent) {
    this.currentPageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.getLeiters();
  }

  public getLeiters() {
    var parameter = this.getLeiterParamter();
    this._api.getTypeRequest('leiters', parameter.join(""))?.subscribe((res: any) => {
      if (res.status) {
        this.leiters = [];
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          let personJson = element.person;
          let person: Person = new Person(personJson.id, personJson.firstname, personJson.lastname, personJson.birthdate, personJson.street, personJson.house_number, personJson.postal_code, personJson.city, personJson.telefon_number, personJson.mobile_number);
          let leiter: Leiter = new Leiter(element.id, person, !!+element.pony, !!+element.signed_off, !!+element.active, !!+element.member_of_honour, !!+element.contribution_paid, !!+element.course_completed, null, element.messdiener_gruppe, element.ausschuss);
          this.leiters.push(leiter);
        }
        this.leiterCount = res.count;
        this.dataSource.data = this.leiters;
        this.table.renderRows();
      } else {
        console.log(res);
      }
    }, err => {
      console.log(err);
    });
  }

  getLeiterParamter() : string[]{
    let search = this.searchInput.replace(/\s/g,''); // Remove whitespace
    search = search.toLowerCase(); // Datasource defaults to lowercase matches
    let parameter: string[] =
      [
        '&index=' + this.currentPageIndex,
        '&size=' + this.currentPageSize,
        '&sortProperty=' + this.sortProperty,
        '&sortDirection=' + this.sortDirection,
        '&searchInput=' + search
      ];
    addFilterParamter("active", this.activeCheckboxChecked, this.activeToggle);
    addFilterParamter("signedOff", this.signedOffCheckboxChecked, this.signedOffToggle);
    addFilterParamter("ausschuss", this.ausschussCheckboxChecked, this.ausschussToggle);
    addFilterParamter("messdienerGruppe", this.messdienerGruppeCheckboxChecked, this.messdienerGruppeToggle);
    addFilterParamter("contributionPaid", this.contributionCheckboxChecked, this.contributionToggle);
    addFilterParamter("courseCompleted", this.courseCheckboxChecked, this.courseToggle);

    if(this.pony){
      parameter.push('&pony=' + true);
    }

    if(this.memberOfHonour){
      parameter.push('&memberOfHonour=' + true);
    }

    function addFilterParamter(name: string, checkBoxChecked: Boolean, toggleValue: Boolean){
      if(!checkBoxChecked){
        parameter.push("&"+name+"=");
      }else{
        parameter.push("&"+name+"="+toggleValue);
      }
    }

    return parameter;
  }

  searchLeiter(input: string){
    this.searchInput = input;
    this.getLeitersAndGoToStart();
  }

  getLeitersAndGoToStart() {
    this.paginator.pageIndex = 0;
    this.currentPageIndex = 0;
    this.getLeiters();
  }

  onMatSortChange(sort: Sort) {
    this.sortProperty = sort.active;
    this.sortDirection = sort.direction == "" ? "asc" : sort.direction;
    this.getLeiters();
  }


  open(leiter: Leiter) {
    this.backupThisLeiter(leiter);
    this.selectedLeiter = leiter;
    this.presentModal();
  }

  private backupThisLeiter(leiter: Leiter) {
    this.backupLeiter.active = leiter.active;
    this.backupLeiter.pony = leiter.pony;
    this.backupLeiter.memberOfHonour = leiter.memberOfHonour;
    this.backupLeiter.signedOff = leiter.signedOff;
    this.backupLeiter.contributionPaid = leiter.contributionPaid;
    this.backupLeiter.courseCompleted = leiter.courseCompleted;

    this.backupLeiter.person.firstname = leiter.person.firstname;
    this.backupLeiter.person.lastname = leiter.person.lastname;
    this.backupLeiter.person.birthdate = leiter.person.birthdate;
    this.backupLeiter.person.street = leiter.person.street;
    this.backupLeiter.person.houseNumber = leiter.person.houseNumber;
    this.backupLeiter.person.postalCode = leiter.person.postalCode;
    this.backupLeiter.person.city = leiter.person.city;
    this.backupLeiter.person.telefonNumber = leiter.person.telefonNumber;
    this.backupLeiter.person.mobileNumber = leiter.person.mobileNumber;
  }

  private restoreLeiter() {
    this.selectedLeiter.active = this.backupLeiter.active;
    this.selectedLeiter.pony = this.backupLeiter.pony;
    this.selectedLeiter.memberOfHonour = this.backupLeiter.memberOfHonour;
    this.selectedLeiter.signedOff = this.backupLeiter.signedOff;
    this.selectedLeiter.contributionPaid = this.backupLeiter.contributionPaid;
    this.selectedLeiter.courseCompleted = this.backupLeiter.courseCompleted;

    this.selectedLeiter.person.firstname = this.backupLeiter.person.firstname;
    this.selectedLeiter.person.lastname = this.backupLeiter.person.lastname;
    this.selectedLeiter.person.birthdate = this.backupLeiter.person.birthdate;
    this.selectedLeiter.person.street = this.backupLeiter.person.street;
    this.selectedLeiter.person.houseNumber = this.backupLeiter.person.houseNumber;
    this.selectedLeiter.person.postalCode = this.backupLeiter.person.postalCode;
    this.selectedLeiter.person.city = this.backupLeiter.person.city;
    this.selectedLeiter.person.telefonNumber = this.backupLeiter.person.telefonNumber;
    this.selectedLeiter.person.mobileNumber = this.backupLeiter.person.mobileNumber;
  }

  addLeiter() {
    this.addButton._elementRef.nativeElement.blur();
    var person: Person = new Person();
    var leiter: Leiter = new Leiter();
    leiter.active = true;
    leiter.pony = this.pony;
    leiter.memberOfHonour = this.memberOfHonour;
    leiter.signedOff = false;
    leiter.contributionPaid = false;
    leiter.courseCompleted = false;
    leiter.person = person;
    leiter.person.birthdate = null;
    this.displayDeleteButton = false;
    this.open(leiter);
  }

  saveLeiter(leiter: Leiter) {
    this.loadingService.present();
    var leiterValues = { 
      "id": null,
      "active": this.selectedLeiter.active,
      "pony": this.selectedLeiter.pony,
      "member_of_honour": this.selectedLeiter.memberOfHonour,
      "signed_off": this.selectedLeiter.signedOff,
      "contribution_paid": this.selectedLeiter.contributionPaid,
      "course_completed": this.selectedLeiter.courseCompleted,
      "firstname": this.tryGetString(this.selectedLeiter.person.firstname),
      "lastname": this.tryGetString(this.selectedLeiter.person.lastname),
      "birthdate": this.tryGetDateString(this.selectedLeiter.person.birthdate),
      "telefon_number": this.tryGetString(this.selectedLeiter.person.telefonNumber),
      "mobile_number": this.tryGetString(this.selectedLeiter.person.mobileNumber),
      "street": this.tryGetString(this.selectedLeiter.person.street),
      "house_number": this.tryGetString(this.selectedLeiter.person.houseNumber),
      "postal_code": this.tryGetNumber(this.selectedLeiter.person.postalCode),
      "city": this.tryGetString(this.selectedLeiter.person.city)
    };

    if (this.displayDeleteButton) {
      leiterValues.id = this.selectedLeiter.id;
    }
    this._api.postTypeRequest('leiter', leiterValues)?.subscribe((res: any) => {
      this.getLeiters();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  tryGetDateString(value: Date){
    if(value == undefined){
      return null
    }
    return value;
  }

  tryGetString(value:string){
    return value || null;
  }

  tryGetNumber(value:number){
    return value || null;
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {leiterComponent: this}
    });
    await popover.present();
  }
}
