import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Messdiener } from 'src/entities/messdiener';
import { Messdienerveranstaltung } from 'src/entities/messdienerveranstaltung';
import { Person } from 'src/entities/person';
import { MessdienerveranstaltungDetailAddComponent } from '../messdienerveranstaltung-detail-add/messdienerveranstaltung-detail-add.component';
import { MessdienerveranstaltungComponent } from '../messdienerveranstaltung/messdienerveranstaltung.component';

@Component({
  selector: 'app-messdienerveranstaltung-detail',
  templateUrl: './messdienerveranstaltung-detail.component.html',
  styleUrls: ['./messdienerveranstaltung-detail.component.scss'],
})
export class MessdienerveranstaltungDetailComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<Messdiener>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('messdienerAddButton') messdienerAddButton;
  @Input() messdienerveranstaltungComponent: MessdienerveranstaltungComponent;
  mobile: Boolean;
  dataSource = new MatTableDataSource<Messdiener>();
  columnsToDisplay = ['messdiener.firstname', 'messdiener.lastname', 'messdiener.delete'];
  messdieners: Messdiener[] = [];

  currentStartDate: String = "";
  dateCorrect = false;

  addModal: any;
  deleteAlert: any;

  constructor(
    private _api: ApiService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    private alertController: AlertController,
    private popover:PopoverController
  ) { }

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);

    if(this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.id != null){
      this.loadingService.present();
      this.getMessdieners();
    }

    try{
      var veranstaltungMoment = moment(this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.startDate);
      veranstaltungMoment.add(1, 'hours');
      this.currentStartDate = veranstaltungMoment.toISOString().slice(0,16);this.dateCorrect = true;
    }catch(e){
      this.dateCorrect = false;
    }
  }

  public startDateChanged(date: any){
    try{
      if(date == ""){
        this.dateCorrect = false;
      }else{
        this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.startDate = new Date(date);
        this.dateCorrect = true;
      }
    }catch(exception){
      this.dateCorrect = false;
    }
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  public getMessdieners() {
    this._api.getTypeRequest('messdienerveranstaltung/' + this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.id, "")?.subscribe((res: any) => {
      if (res.status) {
        this.messdieners = [];
        for (let index = 0; index < res.data.messdiener.length; index++) {
          const element = res.data.messdiener[index];
          let person: Person = new Person();
          person.firstname = element.person.firstname;
          person.lastname = element.person.lastname;
          let messdiener: Messdiener = new Messdiener(element.id, person);
          this.messdieners.push(messdiener);
        }
        this.dataSource.data = this.messdieners;
        this.table.renderRows();
        this.loadingService.dismiss();
      } else {
        console.log(res);
        this.loadingService.dismiss();
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }
  
  deleteselectedMessdienerveranstaltung(){
    this.messdienerveranstaltungComponent.presentDeleteAlert();
  }

  saveselectedMessdienerveranstaltung(messdienerveranstaltung: Messdienerveranstaltung){
    this.messdienerveranstaltungComponent.saveMessdienerVeranstaltung(messdienerveranstaltung);
    this.modalController.dismiss(true);
  }

  async presentAddModal() {
    this.messdienerAddButton._elementRef.nativeElement.blur();
    this.messdienerveranstaltungComponent.otherModalIsNull = false;
    this.addModal = await this.modalController.create({
      component: MessdienerveranstaltungDetailAddComponent,
      componentProps: {
        'messdienerveranstaltungDetailComponent': this
      }
    });
    await this.addModal.present();
  }

  addMessdienerToMessdienerVeranstaltung(messdienerID: string){
    var body = { 
      "veranstaltung_id": this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.id,
      "messdienerId": messdienerID
    };
    this.loadingService.present();
    this._api.postTypeRequest('messdienerveranstaltung/messdiener/add', body)?.subscribe((res: any) => {
      var messdiener = this.messdienerveranstaltungComponent.activeMessdieners.find(messdiener => messdiener.id == messdienerID);
      this.messdieners.push(messdiener);
      this.table.renderRows();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  async presentMessdienerDeleteAlert(messdiener: Messdiener) {
    this.messdienerveranstaltungComponent.otherModalIsNull = false;
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + messdiener.person.firstname + " " + messdiener.person.lastname + "  von der Veranstaltung "+this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.name+" entfernen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Entfernen',
          handler: () => {
            this.loadingService.present();
            var body = { 
              "veranstaltung_id": this.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.id,
              "messdienerId": messdiener.id
            };
            this._api.postTypeRequest('messdienerveranstaltung/messdiener/delete', body)?.subscribe((res: any) => {
              const index = this.messdieners.indexOf(messdiener, 0);
              if (index > -1) {
                this.messdieners.splice(index, 1);
              }
              this.table.renderRows();
              this.loadingService.dismiss();
            }, err => {
              console.log(err);
              this.loadingService.dismiss();
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }


  @HostListener('window:popstate', ['$event'])
  dismissAddAlert() {
    if (this.addModal != null) {
      this.addModal.dismiss();
      this.addModal = null;
      this.messdienerveranstaltungComponent.otherModalIsNull = true;
    }
    if (this.deleteAlert != null) {
      this.deleteAlert.dismiss();
      this.deleteAlert = null;
      this.messdienerveranstaltungComponent.otherModalIsNull = true;
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {messdienerveranstaltungDetailComponent: this}
    });
    await popover.present();
  }

}
