import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './auth/components/login/login.component';
import { LeiterComponent } from './leiter/components/leiter/leiter.component';
import { LeiterrundeComponent } from './leiter/components/leiterrunde/leiterrunde.component';
import { LeiterrundeDetailComponent } from './leiter/components/leiterrunde-detail/leiterrunde-detail.component';
import { LeiterveranstaltungComponent } from './leiter/components/leiterveranstaltung/leiterveranstaltung.component';
import { LeiterveranstaltungDetailComponent } from './leiter/components/leiterveranstaltung-detail/leiterveranstaltung-detail.component';
import { LeiterveranstaltungDetailAddComponent } from './leiter/components/leiterveranstaltung-detail-add/leiterveranstaltung-detail-add.component';
import { LeiterpflichtveranstaltungDetailComponent } from './leiter/components/leiterpflichtveranstaltung-detail/leiterpflichtveranstaltung-detail.component';
import { MessdienerveranstaltungComponent } from './messdiener/components/messdienerveranstaltung/messdienerveranstaltung.component';
import { MessdienerveranstaltungDetailComponent } from './messdiener/components/messdienerveranstaltung-detail/messdienerveranstaltung-detail.component';
import { MessdienerveranstaltungDetailAddComponent } from './messdiener/components/messdienerveranstaltung-detail-add/messdienerveranstaltung-detail-add.component';
import { PonyComponent } from './leiter/components/pony/pony.component';
import { MessdienerComponent } from './messdiener/messdiener/messdiener.component';
import { MessdienerDetailComponent } from './messdiener/messdiener-detail/messdiener-detail.component';
import { MessdienerGruppeComponent } from './messdiener/messdiener-gruppe/messdiener-gruppe.component';
import { MessdienerGruppeDetailComponent } from './messdiener/messdiener-gruppe-detail/messdiener-gruppe-detail.component';
import { MessdienerGruppeDetailAddComponent } from './messdiener/messdiener-gruppe-detail-add/messdiener-gruppe-detail-add.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { getGermanPaginatorIntl } from './helper/german-paginator-intl';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {AuthModule} from './auth/auth/auth.module';
import { FormsModule } from '@angular/forms';
import { LeiterDetailsPage } from './leiter-details/leiter-details.page';
import { IonicStorageModule } from '@ionic/storage-angular';
import { DatePipe } from '@angular/common';
import { SettingsPopOverPageModule } from './settings-pop-over/settings-pop-over.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DateTimeFormatPipe } from './helper/dateTimeFormatPipe';
import { AusschussComponent } from './leiter/components/ausschuss/ausschuss.component';
import { AusschussDetailComponent } from './leiter/components/ausschuss-detail/ausschuss-detail.component';
import { AusschussDetailAddComponent } from './leiter/components/ausschuss-detail-add/ausschuss-detail-add.component';
import { EhrenmitgliedComponent } from './leiter/components/ehrenmitglied/ehrenmitglied.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LeiterComponent,
    LeiterrundeComponent,
    LeiterrundeDetailComponent,
    LeiterveranstaltungComponent,
    LeiterveranstaltungDetailComponent,
    LeiterveranstaltungDetailAddComponent,
    LeiterpflichtveranstaltungDetailComponent,
    AusschussComponent,
    AusschussDetailComponent,
    AusschussDetailAddComponent,
    PonyComponent,
    EhrenmitgliedComponent,
    LeiterDetailsPage,
    MessdienerComponent,
    MessdienerDetailComponent,
    MessdienerGruppeComponent,
    MessdienerGruppeDetailComponent,
    MessdienerGruppeDetailAddComponent,
    MessdienerveranstaltungComponent,
    MessdienerveranstaltungDetailComponent,
    MessdienerveranstaltungDetailAddComponent,
    DateTimeFormatPipe
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AuthModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    SettingsPopOverPageModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    DatePipe,
    DateTimeFormatPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
