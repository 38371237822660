import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Leiter } from 'src/entities/leiter';
import { LeiterComponent } from '../leiter/components/leiter/leiter.component';
import { SettingsPopOverPage } from '../settings-pop-over/settings-pop-over.page';

@Component({
  selector: 'app-leiter-details',
  templateUrl: './leiter-details.page.html',
  styleUrls: ['./leiter-details.page.scss'],
})
export class LeiterDetailsPage implements OnInit {

  @Input() leiterComponent: LeiterComponent;
  mobile: Boolean;

  constructor(
    public modalController: ModalController,
    private popover:PopoverController) { }

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  deleteSelectedLeiter() {
    this.leiterComponent.presentDeleteAlert();
  }

  saveSelectedLeiter(leiter: Leiter) {
    this.leiterComponent.saveLeiter(leiter);
    this.modalController.dismiss(true);
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {leiterDetailsPage: this}
    });
    await popover.present();
  }
}
