import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController } from '@ionic/angular';
import { DateTimeFormatPipe } from 'src/app/helper/dateTimeFormatPipe';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Anwesenheit } from 'src/entities/anwesenheit';
import { Leiter } from 'src/entities/leiter';
import { Leiterveranstaltung } from 'src/entities/leiterveranstaltung';
import { Person } from 'src/entities/person';
import { LeiterpflichtveranstaltungDetailComponent } from '../leiterpflichtveranstaltung-detail/leiterpflichtveranstaltung-detail.component';
import { LeiterveranstaltungDetailComponent } from '../leiterveranstaltung-detail/leiterveranstaltung-detail.component';

@Component({
  selector: 'app-leiterveranstaltung',
  templateUrl: './leiterveranstaltung.component.html',
  styleUrls: ['./leiterveranstaltung.component.css']
})
export class LeiterveranstaltungComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<Leiterveranstaltung>;
  @ViewChild('addButton') addButton;
  dataSource = new MatTableDataSource<Leiterveranstaltung>();
  columnsToDisplay = ['leiterveranstaltung.name', 'leiterveranstaltung.startDate'];
  leiterveranstaltungen: Leiterveranstaltung[] = [];
  backupLeiterveranstaltung: Leiterveranstaltung = new Leiterveranstaltung();
  selectedLeiterveranstaltung!: Leiterveranstaltung;
  anwesenheiten: Anwesenheit[] = [];
  displayDeleteButton: boolean = true;

  detailModal: any;
  deleteAlert: any;

  activeLeiters: Leiter[] = [];

  constructor(
    private _api: ApiService,
    public modalController: ModalController,
    private alertController: AlertController,
    public loadingService: LoadingService,
    public dateTimeFormatFilter: DateTimeFormatPipe) { }

  ngOnInit(): void {
    this._api.getTypeRequest('anwesenheiten', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let anwesenheit: Anwesenheit = new Anwesenheit(element.id, element.kind);
          this.anwesenheiten.push(anwesenheit);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
    this.getActiveLeiter();
    this.getLeiterVeranstaltungen();
  }

  getLeiterVeranstaltungen(){
    this.loadingService.present();
    this._api.getTypeRequest('leiterveranstaltungen', '')?.subscribe((res: any) => {
      this.leiterveranstaltungen = [];
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let leiterveranstaltung: Leiterveranstaltung = new Leiterveranstaltung(element.id, null, element.name, element.start_date, element.mandatory);
          this.leiterveranstaltungen.push(leiterveranstaltung);
          this.dataSource.data = this.leiterveranstaltungen;
          this.table.renderRows();
        }
        this.loadingService.dismiss();
      } else {
        console.log(res.data);
        this.loadingService.dismiss();
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  open(leiterveranstaltung: Leiterveranstaltung) {
    this.backupThisLeiterVeranstaltung(leiterveranstaltung);
    this.selectedLeiterveranstaltung = leiterveranstaltung;
    this.presentModal(this.selectedLeiterveranstaltung.mandatory);
  }

  private backupThisLeiterVeranstaltung(leiterveranstaltung: Leiterveranstaltung) {
    this.backupLeiterveranstaltung.name = leiterveranstaltung.name;
    this.backupLeiterveranstaltung.startDate = leiterveranstaltung.startDate;
  }

  private restoreLeiterVeranstaltung() {
    this.selectedLeiterveranstaltung.name = this.backupLeiterveranstaltung.name;
    this.selectedLeiterveranstaltung.startDate = this.backupLeiterveranstaltung.startDate;
  }
  
  async presentModal(mandatory: boolean) {
    if(mandatory){
      this.detailModal = await this.modalController.create({
        component: LeiterpflichtveranstaltungDetailComponent,
        componentProps: {
          'leiterveranstaltungComponent': this
        }
      });
    }else{
      this.detailModal = await this.modalController.create({
        component: LeiterveranstaltungDetailComponent,
        componentProps: {
          'leiterveranstaltungComponent': this
        }
      });
    }
    await this.detailModal.present()
    await this.detailModal.onDidDismiss().then((r) => {
      if (!r.data) {
        this.restoreLeiterVeranstaltung();
      }

      this.displayDeleteButton = true;
    });
  }
  
  otherModalIsNull = true;
  @HostListener('window:popstate', ['$event'])
  dismissDetailAlert() {
    if (this.detailModal != null  && this.otherModalIsNull) {
      if (this.deleteAlert != null) {
        this.deleteAlert.dismiss();
        this.deleteAlert = null;
      } else {
        this.detailModal.dismiss();
        this.detailModal = null;
      }
    }
  }

  addLeiterveranstaltung(){
    this.addButton._elementRef.nativeElement.blur();
    var leiterveranstaltung = new Leiterveranstaltung();
    leiterveranstaltung.mandatory = false;
    leiterveranstaltung.startDate = new Date();
    this.displayDeleteButton = false;
    this.open(leiterveranstaltung);
  }

  saveLeiterVeranstaltung(leiterveranstaltung: Leiterveranstaltung) {
    this.loadingService.present();
    var leiterverAnstaltungValues = { 
      "id": null,
      "name": leiterveranstaltung.name,
      "start_date": this.toDateString(leiterveranstaltung.startDate),
      "mandatory": leiterveranstaltung.mandatory
    };
    if (this.displayDeleteButton) {
      leiterverAnstaltungValues.id = leiterveranstaltung.id;
    }
    this._api.postTypeRequest('leiterveranstaltung', leiterverAnstaltungValues)?.subscribe((res: any) => {
      this.getLeiterVeranstaltungen();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  public toDateString(date: Date): string {
    if(typeof(date) == 'string'){
      date = new Date(date);
    }
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }

  async presentDeleteAlert() {
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + this.selectedLeiterveranstaltung.name + " löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Löschen',
          handler: () => {
            this.loadingService.present();
            this._api.deleteTypeRequest('leiterveranstaltung/' + this.selectedLeiterveranstaltung.id + '/delete')?.subscribe((res: any) => {
              this.getLeiterVeranstaltungen();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  getActiveLeiter(){
    this._api.getTypeRequest('messdienergruppen/leiter', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let person = new Person(element.personId, element.firstname, element.lastname);
          let leiter: Leiter = new Leiter(element.id, person);
          this.activeLeiters.push(leiter);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }
}
