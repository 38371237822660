import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Ausschuss } from 'src/entities/ausschuss';
import { Leiter } from 'src/entities/leiter';
import { Person } from 'src/entities/person';
import { AusschussDetailComponent } from '../ausschuss-detail/ausschuss-detail.component';

@Component({
  selector: 'app-ausschuss',
  templateUrl: './ausschuss.component.html',
  styleUrls: ['./ausschuss.component.scss'],
})
export class AusschussComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<Ausschuss>;
  @ViewChild('addButton') addButton;
  dataSource = new MatTableDataSource<Ausschuss>();
  columnsToDisplay = ['ausschuss.name'];
  ausschusse: Ausschuss[] = [];
  selectedAusschuss!: Ausschuss;
  backupAusschuss: Ausschuss = new Ausschuss();
  ausschussCount: number = 0;
  currentPageSize: number = 10;
  currentPageIndex: number = 0;
  displayDeleteButton: boolean = true;

  activeLeiters: Leiter[] = [];

  activeToggle: Boolean = true;
  detailModal: any;
  deleteAlert: any;

  constructor(
    private _api: ApiService,
    public modalController: ModalController,
    private alertController: AlertController,
    public loadingService: LoadingService,
    private popover: PopoverController,
    public _auth: AuthService
  ) { }

  ngOnInit() {
    this.loadingService.present();
    this.getActiveLeiter();
    this.getAusschusse();
  }

  getAusschusseParamter(): Boolean {
    return this.activeToggle;
  }

  getActiveLeiter() {
    this._api.getTypeRequest('messdienergruppen/leiter', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let person = new Person(element.personId, element.firstname, element.lastname);
          let leiter: Leiter = new Leiter(element.id, person);
          this.activeLeiters.push(leiter);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }

  getAusschusse() {
    this.ausschusse = [];
    let parameter: string[] =
      [
        '&active=' + this.activeToggle,
        '&index=' + this.currentPageIndex,
        '&size=' + this.currentPageSize,
      ];
    this._api.getTypeRequest('ausschusse', parameter.join(""))?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let ausschuss: Ausschuss = new Ausschuss(element.id, [], element.name, element.active);
          this.ausschusse.push(ausschuss);
        }
        this.ausschussCount = res.count;
        this.dataSource.data = this.ausschusse;
        this.table.renderRows();
        this.loadingService.dismiss();
      } else {
        this.loadingService.dismiss();
        console.log(res.data);
      }
    }, err => {
      this.loadingService.dismiss();
      console.log(err);
    });
  }

  public getServerData(event?: PageEvent) {
    this.currentPageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.getAusschusse();
  }

  async presentModal() {
    this.detailModal = await this.modalController.create({
      component: AusschussDetailComponent,
      componentProps: {
        'ausschussComponent': this
      }
    });
    await this.detailModal.present();
    await this.detailModal.onDidDismiss().then((r) => {
      if (!r.data) {
        this.restoreAusschuss();
      }

      this.displayDeleteButton = true;
    });
  }

  otherModalIsNull = true;
  @HostListener('window:popstate', ['$event'])
  dismissDetailAlert() {
    if (this.detailModal != null && this.otherModalIsNull) {
      if (this.deleteAlert != null) {
        this.deleteAlert.dismiss();
        this.deleteAlert = null;
      } else {
        this.detailModal.dismiss();
        this.detailModal = null;
      }
    }
  }

  async presentDeleteAlert() {
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + this.selectedAusschuss.name + " löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Löschen',
          handler: () => {
            this.loadingService.present();
            this._api.deleteTypeRequest('ausschuss/' + this.selectedAusschuss.id + '/delete')?.subscribe((res: any) => {
              this.getAusschusse();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  open(ausschuss: Ausschuss) {
    this.backupThisAusschuss(ausschuss);
    this.selectedAusschuss = ausschuss;
    this.presentModal();
  }

  private backupThisAusschuss(ausschuss: Ausschuss) {
    this.backupAusschuss.active = ausschuss.active;
    this.backupAusschuss.name = ausschuss.name;
  }

  private restoreAusschuss() {
    this.selectedAusschuss.active = this.backupAusschuss.active;
    this.selectedAusschuss.name = this.backupAusschuss.name;
  }

  addAusschuss() {
    this.addButton._elementRef.nativeElement.blur();
    var ausschuss = new Ausschuss();
    ausschuss.active = true;
    this.displayDeleteButton = false;
    this.open(ausschuss);
  }

  saveAusschuss(ausschuss: Ausschuss) {
    this.loadingService.present();
    var ausschussValues = {
      "id": null,
      "name": ausschuss.name,
      "active": ausschuss.active
    };
    if (this.displayDeleteButton) {
      ausschussValues.id = ausschuss.id;
    }
    this._api.postTypeRequest('ausschuss', ausschussValues)?.subscribe((res: any) => {
      this.getAusschusse();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: { ausschussComponent: this }
    });
    await popover.present();
  }

}
