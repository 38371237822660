import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { AusschussComponent } from './leiter/components/ausschuss/ausschuss.component';
import { EhrenmitgliedComponent } from './leiter/components/ehrenmitglied/ehrenmitglied.component';
import { LeiterComponent } from './leiter/components/leiter/leiter.component';
import { LeiterrundeComponent } from './leiter/components/leiterrunde/leiterrunde.component';
import { LeiterveranstaltungComponent } from './leiter/components/leiterveranstaltung/leiterveranstaltung.component';
import { PonyComponent } from './leiter/components/pony/pony.component';
import { MessdienerveranstaltungComponent } from './messdiener/components/messdienerveranstaltung/messdienerveranstaltung.component';
import { MessdienerGruppeComponent } from './messdiener/messdiener-gruppe/messdiener-gruppe.component';
import { MessdienerComponent } from './messdiener/messdiener/messdiener.component';
import { AuthGuardService } from './services/auth-guard.service'
import { LoginGuardService } from './services/login-guard.service';

const routes: Routes = [
  { path: '', component: LeiterComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService] },
  { path: 'ponys', component: PonyComponent, canActivate: [AuthGuardService] },
  { path: 'ehrenmitglieder', component: EhrenmitgliedComponent, canActivate: [AuthGuardService] },
  { path: 'messdiener', component: MessdienerComponent, canActivate: [AuthGuardService] },
  { path: 'leiterrunden', component: LeiterrundeComponent, canActivate: [AuthGuardService] },
  { path: 'leiterveranstaltungen', component: LeiterveranstaltungComponent, canActivate: [AuthGuardService] },
  { path: 'ausschusse', component: AusschussComponent, canActivate: [AuthGuardService] },
  { path: 'messdienergruppen', component: MessdienerGruppeComponent, canActivate: [AuthGuardService] },
  { path: 'messdienerveranstaltungen', component: MessdienerveranstaltungComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
