import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.authService.setAppPages();
    if(this.authService.isTokenExpired()){
      this.router.navigate(["login"]);
      return false;
    }
    var path = "";
    if(route.url[0] != undefined){
      path = route.url[0].path;
    }
    if(path != "messdiener" && path != "messdienergruppen" && this.authService.currentRole == 'messdiener_striche'){
      this.router.navigate(["messdiener"]);
      return false;
    }
    return true;
  }
}
