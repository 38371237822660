import { Component, Input, OnInit } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { LeiterDetailsPage } from '../leiter-details/leiter-details.page';
import { AusschussDetailComponent } from '../leiter/components/ausschuss-detail/ausschuss-detail.component';
import { AusschussComponent } from '../leiter/components/ausschuss/ausschuss.component';
import { LeiterComponent } from '../leiter/components/leiter/leiter.component';
import { LeiterpflichtveranstaltungDetailComponent } from '../leiter/components/leiterpflichtveranstaltung-detail/leiterpflichtveranstaltung-detail.component';
import { LeiterveranstaltungDetailComponent } from '../leiter/components/leiterveranstaltung-detail/leiterveranstaltung-detail.component';
import { PonyComponent } from '../leiter/components/pony/pony.component';
import { MessdienerveranstaltungDetailComponent } from '../messdiener/components/messdienerveranstaltung-detail/messdienerveranstaltung-detail.component';
import { MessdienerDetailComponent } from '../messdiener/messdiener-detail/messdiener-detail.component';
import { MessdienerGruppeDetailComponent } from '../messdiener/messdiener-gruppe-detail/messdiener-gruppe-detail.component';
import { MessdienerGruppeComponent } from '../messdiener/messdiener-gruppe/messdiener-gruppe.component';
import { MessdienerComponent } from '../messdiener/messdiener/messdiener.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-settings-pop-over',
  templateUrl: './settings-pop-over.page.html',
  styleUrls: ['./settings-pop-over.page.scss'],
})
export class SettingsPopOverPage implements OnInit {

  clearStricheAlert: any;
  promotePonysAlert: any;
  leiterPdfAlert: any;
  ponyPdfAlert: any;
  messdienerPdfAlert: any;
  resetSignedOffAlert: any;

  @Input() messdienerComponent: MessdienerComponent;
  @Input() ponyComponent: PonyComponent;
  @Input() leiterComponent: LeiterComponent;
  @Input() leiterDetailsPage: LeiterDetailsPage;
  @Input() ausschussComponent: AusschussComponent;
  @Input() ausschussDetailComponent: AusschussDetailComponent;
  @Input() messdienerDetailComponent: MessdienerDetailComponent;
  @Input() messdienerGruppeComponent: MessdienerGruppeComponent;
  @Input() messdienerGruppeDetailComponent: MessdienerGruppeDetailComponent;
  @Input() leiterveranstaltungDetailComponent: LeiterveranstaltungDetailComponent;
  @Input() messdienerveranstaltungDetailComponent: MessdienerveranstaltungDetailComponent;
  @Input() leiterpflichtveranstaltungDetailComponent: LeiterpflichtveranstaltungDetailComponent;

  constructor(
    private _api: ApiService,
    private popover:PopoverController,
    public alertController: AlertController,
    public _auth: AuthService) {} 
  ngOnInit() {
  }
  ClosePopover()
  {
    this.popover.dismiss();
  }

  clearStriche(){
    this._api.getTypeRequest('striche/clear', '')?.subscribe((res: any) => {
      if (res.status) {
        this.messdienerComponent.loadingService.present();
        this.messdienerComponent?.getMessdieners();
        this.messdienerComponent.loadingService.dismiss();
      } else {
        console.log(res);
      }
    }, err => {
      console.log(err);
    });
  }

  async presentClearStricheAlert() {
    this.popover.dismiss();
    this.clearStricheAlert = await this.alertController.create({
      header: 'Striche löschen',
      message: "Willst du wirklich alle Striche von allen Messdienern löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Löschen',
          handler: () => {
            this.clearStriche();
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'clear Striche alert'
    };

    history.pushState(modalState, null);
    await this.clearStricheAlert.present();
  }

  promoteAllPonys(){
    this._api.getTypeRequest('ponys/promotion', '')?.subscribe((res: any) => {
      if (res.status) {
        this.ponyComponent.loadingService.present();
        this.ponyComponent?.getLeiters();
        this.ponyComponent.loadingService.dismiss();
      } else {
        console.log(res);
      }
    }, err => {
      console.log(err);
    });
  }

  async presentPromotePonysAlert() {
    this.popover.dismiss();
    this.promotePonysAlert = await this.alertController.create({
      header: 'Leiter',
      message: "Willst du wirklich alle Ponys zu Leitern machen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Bestätigen',
          handler: () => {
            this.promoteAllPonys();
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'promote Ponys alert'
    };

    history.pushState(modalState, null);
    await this.promotePonysAlert.present();
  }

  async presentLeiterPdfAlert() {
    this.popover.dismiss();
    this.leiterPdfAlert = await this.alertController.create({
      header: 'Leiter Liste herunterladen',
      message: "Wie lautet der Titel der Liste?",
      inputs: [
        {
          name: 'title',
          placeholder: 'Leiter'
        }
      ],
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Bestätigen',
          handler: data => {
            this.getLeiterPdf(data.title);
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'leiter pdf alert'
    };

    history.pushState(modalState, null);
    await this.leiterPdfAlert.present();
  }


  getLeiterPdf(title: string){
    window.open(this._api.getLeiterPdfUrl()+this.leiterComponent.getLeiterParamter().join("")+"&title="+title, "_blank");
  }

  async presentPonyPdfAlert() {
    this.popover.dismiss();
    this.ponyPdfAlert = await this.alertController.create({
      header: 'Pony Liste herunterladen',
      message: "Wie lautet der Titel der Liste?",
      inputs: [
        {
          name: 'title',
          value: 'Ponys'
        }
      ],
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Bestätigen',
          handler: data => {
            this.getPonyPdf(data.title);
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'pony pdf alert'
    };

    history.pushState(modalState, null);
    await this.ponyPdfAlert.present();
  }

  getPonyPdf(title: string){
    window.open(this._api.getLeiterPdfUrl()+this.ponyComponent.getLeiterParamter().join("")+"&title="+title, "_blank");
  }

  async presentMessdienerPdfAlert() {
    this.popover.dismiss();
    this.messdienerPdfAlert = await this.alertController.create({
      header: 'Messdiener Liste herunterladen',
      message: "Wie lautet der Titel der Liste?",
      inputs: [
        {
          name: 'title',
          value: 'Messdiener'
        }
      ],
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Bestätigen',
          handler: data => {
            this.getMessdienerPdf(data.title);
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'messdiener pdf alert'
    };

    history.pushState(modalState, null);
    await this.messdienerPdfAlert.present();
  }

  getMessdienerPdf(title: string){
    window.open(this._api.getMessdienerPdfUrl()+this.messdienerComponent.getMessdienerParamter().join("")+"&title="+title, "_blank");
  }

  getLeiterStrichePdf(){
    window.open(this._api.getLeiterStrichePdfUrl(this.leiterDetailsPage.leiterComponent.selectedLeiter.id), "_blank");
  }

  getAllLeiterStrichePdf(){
    window.open(this._api.getAllLeiterStrichePdfUrl()+this.leiterComponent.getLeiterParamter().join(""), "_blank");
  }

  getMessdienerStrichePdf(){
    window.open(this._api.getMessdienerStrichePdfUrl(this.messdienerDetailComponent.messdienerComponent.selectedMessdiener.id), "_blank");
  }

  getMessdienerGruppePdf(){
    window.open(this._api.getMessdienerGruppePdfUrl(this.messdienerGruppeDetailComponent.messdienergruppeComponent.selectedMessdienergruppe.id), "_blank");
  }

  getMessdienerGruppenPdf(){
    window.open(this._api.getMessdienerGruppenPdfUrl(this.messdienerGruppeComponent.getMessdienerGruppenParamter()), "_blank");
  }

  getMessdienerVeranstaltungPdf(){
    window.open(this._api.getMessdienerVeranstaltungPdfUrl(this.messdienerveranstaltungDetailComponent.messdienerveranstaltungComponent.selectedMessdienerveranstaltung.id), "_blank");
  }

  getLeiterVeranstaltungPdf(){
    window.open(this._api.getLeiterVeranstaltungPdfUrl(this.leiterveranstaltungDetailComponent.leiterveranstaltungComponent.selectedLeiterveranstaltung.id), "_blank");
  }

  getLeiterPflichtVeranstaltungPdf(){
    window.open(this._api.getLeiterVeranstaltungPdfUrl(this.leiterpflichtveranstaltungDetailComponent.leiterveranstaltungComponent.selectedLeiterveranstaltung.id), "_blank");
  }

  async resetSignedOff(){
    this.popover.dismiss();
    this.resetSignedOffAlert = await this.alertController.create({
      header: 'Dauerabmeldungen zurücksetzen',
      message: "Willst du wirklich alle Dauerabmeldungen zurücksetzen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Zurücksetzen',
          handler: () => {
            this.leiterComponent.loadingService.present();
            this._api.getTypeRequest('leiter/resetSignedOff', '')?.subscribe((res: any) => {
              this.popover.dismiss();
              this.leiterComponent?.getLeiters();
              this.leiterComponent.loadingService.dismiss();
            }, err => {
              console.log(err);
              this.leiterComponent.loadingService.dismiss();
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'reset SignedOff alert'
    };

    history.pushState(modalState, null);
    await this.resetSignedOffAlert.present();
  }
}
