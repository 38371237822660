import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public showMenu = true;
  constructor(
    private titleService: Title,
    public router: Router,
    public _auth: AuthService) {
    this.titleService.setTitle('Vorstand Verwaltung');
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        if(val.url == "/login"){
          this.showMenu = false;
        }else{
          this.showMenu = true;
        }
      }
    });
  }
  public logout() {
    this._auth.clearStorage()
    this.router.navigateByUrl('/login', { replaceUrl: true }) 
  }
}
