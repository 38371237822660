export class Person {
    private _id?: string;
    private _firstname?: string;
    private _lastname?: string;
    private _birthdate?: Date;
    private _street?: string;
    private _houseNumber?: string;
    private _postalCode?: number;
    private _city?: string;
    private _telefonNumber?: string;
    private _mobileNumber?: string;

    constructor(
        id?: string,
        firstname?: string,
        lastname?: string,
        birthdate?: Date,
        street?: string,
        houseNumber?: string,
        postalCode?: number,
        city?: string,
        telefonNumber?: string,
        mobileNumber?: string) {
            this._id = id;
            this._firstname = firstname,
            this._lastname = lastname,
            this._birthdate = birthdate,
            this._street = street,
            this._houseNumber = houseNumber,
            this._postalCode = postalCode,
            this._city = city,
            this._telefonNumber = telefonNumber,
            this._mobileNumber = mobileNumber
         }

    public get id() {
        return this._id!;
    }

    public set id(id: string) {
        this._id = id!;
    }

    public get firstname() {
        return this._firstname!;
    }

    public set firstname(firstname: string) {
        this._firstname = firstname;
    }

    public get lastname() {
        return this._lastname!;
    }

    public set lastname(lastname: string) {
        this._lastname = lastname;
    }

    public get birthdate() {
        return this._birthdate!;
    }

    public set birthdate(birthdate: Date) {
        this._birthdate = birthdate;
    }

    public get street() {
        return this._street!;
    }

    public set street(street: string) {
        this._street = street;
    }

    public get houseNumber() {
        return this._houseNumber!;
    }

    public set houseNumber(houseNumber: string) {
        this._houseNumber = houseNumber;
    }

    public get postalCode() {
        return this._postalCode!;
    }

    public set postalCode(postalCode: number) {
        this._postalCode = postalCode;
    }

    public get city() {
        return this._city!;
    }

    public set city(city: string) {
        this._city = city;
    }

    public get telefonNumber() {
        return this._telefonNumber!;
    }

    public set telefonNumber(telefonNumber: string) {
        this._telefonNumber = telefonNumber;
    }

    public get mobileNumber() {
        return this._mobileNumber!;
    }

    public set mobileNumber(mobileNumber: string) {
        this._mobileNumber = mobileNumber;
    }

}