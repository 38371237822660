import { Person } from "./person";

export class Anwesenheit {
    constructor(
        private _id: string,
        private _kind: string) { }

    public get id() {
        return this._id;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get kind() {
        return this._kind;
    }

    public set kind(kind: string) {
        this._kind = kind;
    }
}