import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Anwesenheit } from 'src/entities/anwesenheit';
import { Leiter } from 'src/entities/leiter';
import { Leiterrunde } from 'src/entities/leiterrunde';
import { Person } from 'src/entities/person';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ModalController } from '@ionic/angular';
import { LeiterrundeDetailComponent } from '../leiterrunde-detail/leiterrunde-detail.component';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-leiterrunde',
  templateUrl: './leiterrunde.component.html',
  styleUrls: ['./leiterrunde.component.css']
})
export class LeiterrundeComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<Leiterrunde>;
  @ViewChild('addButton') addButton;
  dataSource = new MatTableDataSource<Leiterrunde>();
  columnsToDisplay = ['leiterrunde.month', 'leiterrunde.year'];
  leiterrunden: Leiterrunde[] = [];
  selectedLeiterrunde!: Leiterrunde;
  anwesenheiten: Anwesenheit[] = [];

  detailModal: any;

  constructor(
    private _api: ApiService,
    public modalController: ModalController,
    public loadingService: LoadingService) { }

  async presentModal() {
    this.detailModal = await this.modalController.create({
      component: LeiterrundeDetailComponent,
      componentProps: {
        'leiterrundeComponent': this
      }
    });
    await this.detailModal.present()
    await this.detailModal.onDidDismiss().then((r) => {
      /*if (!r.data) {
        this.restoreMessdiener();
      }
      this.displayDeleteButton = true;*/
    });
  }

  @HostListener('window:popstate', ['$event'])
  dismissDeleteAlert() {
    if (this.detailModal != null) {
        this.detailModal.dismiss();
        this.detailModal = null;
    }
  }

  ngOnInit(): void {
    this.loadingService.present();
    this._api.getTypeRequest('anwesenheiten', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let anwesenheit: Anwesenheit = new Anwesenheit(element.id, element.kind);
          this.anwesenheiten.push(anwesenheit);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
    this.getLeiterrunden();
  }

  getLeiterrunden(){
    this._api.getTypeRequest('leiterrunden', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let leiterrunde: Leiterrunde = new Leiterrunde(element.id, null, Leiterrunde.months[element.month-1], element.year);
          this.leiterrunden.push(leiterrunde);
        }
        this.dataSource.data = this.leiterrunden;
        this.table.renderRows();
        this.loadingService.dismiss();
      } else {
        console.log(res.data);
        this.loadingService.dismiss();
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  open(leiterrunde: Leiterrunde) {
    this.selectedLeiterrunde = leiterrunde;
    this.presentModal();
  }

  ChangeAnwesenheit(leiter: Leiter, anwesenheit: Anwesenheit) { 
    leiter.anwesenheit = anwesenheit;
  }

  addLeiterrunde(){
    this.addButton._elementRef.nativeElement.blur();
    this.loadingService.present();
    this._api.getTypeRequest('create/leiterrunde', '')?.subscribe((res: any) => {
      if (!res.status) {
        console.log(res.data);
        this.loadingService.dismiss();
        if(res.data == "already exist"){
          alert('Leiterrunde für diesen Monat existiert bereits!');
        }
      }
      this.leiterrunden = [];
      this.getLeiterrunden();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

}
