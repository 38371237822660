import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Anwesenheit } from 'src/entities/anwesenheit';
import { Leiter } from 'src/entities/leiter';
import { Leiterveranstaltung } from 'src/entities/leiterveranstaltung';
import { Person } from 'src/entities/person';
import { LeiterveranstaltungComponent } from '../leiterveranstaltung/leiterveranstaltung.component';

@Component({
  selector: 'app-leiterpflichtveranstaltung-detail',
  templateUrl: './leiterpflichtveranstaltung-detail.component.html',
  styleUrls: ['./leiterpflichtveranstaltung-detail.component.scss'],
})
export class LeiterpflichtveranstaltungDetailComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<Leiter>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() leiterveranstaltungComponent: LeiterveranstaltungComponent;
  mobile: Boolean;
  dataSource = new MatTableDataSource<Leiter>();
  columnsToDisplay = ['name', 'anwesenheit'];
  leiters: Leiter[] = [];

  leiterCount: number = 0;
  currentPageSize: number = 5;
  currentPageIndex: number = 0;
  sortProperty: string = "person.lastname";
  sortDirection: string = "asc";
  searchInput = "";

  currentStartDate: String = "";
  dateCorrect = false;

  constructor(
    private _api: ApiService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    private popover:PopoverController
    ) { }

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);

    this.loadingService.present();
    this.getLeiters();

    try{
      var veranstaltungMoment = moment(this.leiterveranstaltungComponent.selectedLeiterveranstaltung.startDate);
      veranstaltungMoment.add(2, 'hours');
      this.currentStartDate = veranstaltungMoment.toISOString().slice(0,16);
      this.dateCorrect = true;
    }catch(e){
      this.dateCorrect = false;
    }
  }

  public startDateChanged(date: any){
    try{
      if(date == ""){
        this.dateCorrect = false;
      }else{
        this.leiterveranstaltungComponent.selectedLeiterveranstaltung.startDate = new Date(date);
        this.dateCorrect = true;
      }
    }catch(exception){
      this.dateCorrect = false;
    }
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  public getLeiters() {
    let search = this.searchInput.replace(/\s/g,''); // Remove whitespace
    search = search.toLowerCase(); // Datasource defaults to lowercase matches
    let parameter: string[] =
      [
        '&index=' + this.currentPageIndex,
        '&size=' + this.currentPageSize,
        '&sortProperty=' + this.sortProperty,
        '&sortDirection=' + this.sortDirection,
        '&searchInput=' + search
      ];

    this._api.getTypeRequest('leiterveranstaltung/leiter/' + this.leiterveranstaltungComponent.selectedLeiterveranstaltung.id, parameter.join(""))?.subscribe((res: any) => {
      if (res.status) {
        this.leiters = [];
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          let person: Person = new Person();
          person.firstname = element.firstname;
          person.lastname = element.lastname;
          let leiter: Leiter = new Leiter(element.id, person);
          leiter.anwesenheit = new Anwesenheit(element.anwesenheit_id, element.anwesenheit_kind);
          this.leiters.push(leiter);
        }
        this.leiterCount = res.count;
        this.dataSource.data = this.leiters;
        this.table.renderRows();
        this.loadingService.dismiss();
      } else {
        console.log(res);
        this.loadingService.dismiss();
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  searchLeiter(input: string){
    this.searchInput = input;
    this.getLeitersAndGoToStart();
  }

  getLeitersAndGoToStart() {
    this.paginator.pageIndex = 0;
    this.currentPageIndex = 0;
    this.getLeiters();
  }

  public getServerData(event?: PageEvent) {
    this.currentPageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.getLeiters();
  }

  onMatSortChange(sort: Sort) {
    this.sortProperty = sort.active;
    this.sortDirection = sort.direction == "" ? "asc" : sort.direction;
    this.getLeiters();
  }

  onAnwesehnheitChange(event: any, leiterId: string){
    this.loadingService.present();
    var body = { 
      "veranstaltung_id": this.leiterveranstaltungComponent.selectedLeiterveranstaltung.id,
      "leiter_id": leiterId,
      "anwesenheit": event.detail.value
    };
    this._api.postTypeRequest('leiterveranstaltung/leiter/anwesenheit', body)?.subscribe((res: any) => {
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  deleteSelectedLeiterVeranstaltung(){
    this.leiterveranstaltungComponent.presentDeleteAlert();
  }

  saveSelectedMessdienerVeranstaltung(leiterveranstaltung: Leiterveranstaltung){
    this.leiterveranstaltungComponent.saveLeiterVeranstaltung(leiterveranstaltung);
    this.modalController.dismiss(true);
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {leiterpflichtveranstaltungDetailComponent: this}
    });
    await popover.present();
  }
}
