import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor() { }

    currentRole = "";
    public appPages = [];

    setAppPages(){
        this.currentRole = localStorage.getItem('role');
        this.appPages = [];
        if(this.currentRole == 'messdiener_striche'){
            this.appPages.push({ title: 'Messdiener', url: '/messdiener', icon: 'body' });
            this.appPages.push({ title: 'Messdienergruppen', url: '/messdienergruppen', icon: 'people' });
        }else{
            this.appPages.push({ title: 'Leiter', url: '', icon: 'person' });
            this.appPages.push({ title: 'Ponys', url: '/ponys', icon: 'person' });
            this.appPages.push({ title: 'Ehrenmitglieder', url: '/ehrenmitglieder', icon: 'heart' });
            this.appPages.push({ title: 'Ausschüsse', url: '/ausschusse', icon: 'hand-right' });
            this.appPages.push({ title: 'Leiterrunden', url: '/leiterrunden', icon: 'people' });
            this.appPages.push({ title: 'Leiter Veranstaltungen', url: '/leiterveranstaltungen', icon: 'alarm' });
            this.appPages.push({ title: 'Messdiener', url: '/messdiener', icon: 'body' });
            this.appPages.push({ title: 'Messdienergruppen', url: '/messdienergruppen', icon: 'people' });
            this.appPages.push({ title: 'Messdiener Veranstaltungen', url: '/messdienerveranstaltungen', icon: 'alarm' });
        }
    }

    getUserDetails() {
        return localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') ?? "") : null;
    }
    setDataInLocalStorage(variableName: string, data: string) {
        localStorage.setItem(variableName, data);
    }
    getToken() {
        return localStorage.getItem('token');
    }

    getTokenExpirationDate(){
        return this.decodeToken(this.getToken()).expires_at;
    }

    isTokenExpired(){
        var expirationDate = this.getTokenExpirationDate();
        if(expirationDate == null){
            return true;
        }
        return new Date(expirationDate.date) < new Date();
    }

    private urlBase64Decode(str: string) {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                // tslint:disable-next-line:no-string-throw
                throw 'Illegal base64url string!';
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }
    
    public decodeToken(token: string = '') {
        if (token === null || token === '') { return { 'upn': '' }; }
        const parts = token.split('.');
        if (parts.length !== 3) {

            throw new Error('JWT must have 3 parts');
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token');
        }
        return JSON.parse(decoded);
    }
    clearStorage() {
        localStorage.clear();
    }
}