import { Person } from "./person";

export class Messdiener {
    private _id?: string;
    private _person?: Person;
    private _striche?: number;
    private _active?: boolean;

    constructor(
        id?: string,
        person?: Person,
        striche?: number,
        active?: boolean,) {
        this._id = id;
        this._person = person;
        this._striche = striche;
        this._active = active;
    }

    public get id() {
        return this._id!;
    }

    public set id(id: string) {
        this._id = id;
    }

    public get person() {
        return this._person!;
    }

    public set person(person: Person) {
        this._person = person;
    }

    public get striche() {
        return this._striche!;
    }

    public set striche(striche: number) {
        this._striche = striche;
    }

    
    public get active() {
        return this._active!;
    }

    public set active(active: boolean) {
        this._active = active;
    }
}