import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController } from '@ionic/angular';
import { DateTimeFormatPipe } from 'src/app/helper/dateTimeFormatPipe';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Messdiener } from 'src/entities/messdiener';
import { Messdienerveranstaltung } from 'src/entities/messdienerveranstaltung';
import { Person } from 'src/entities/person';
import { MessdienerveranstaltungDetailComponent } from '../messdienerveranstaltung-detail/messdienerveranstaltung-detail.component';

@Component({
  selector: 'app-messdienerveranstaltung',
  templateUrl: './messdienerveranstaltung.component.html',
  styleUrls: ['./messdienerveranstaltung.component.scss'],
})
export class MessdienerveranstaltungComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<Messdienerveranstaltung>;
  @ViewChild('addButton') addButton;
  dataSource = new MatTableDataSource<Messdienerveranstaltung>();
  columnsToDisplay = ['messdienerveranstaltung.name', 'messdienerveranstaltung.startDate'];
  messdienerveranstaltungen: Messdienerveranstaltung[] = [];
  backupMessdienerveranstaltung: Messdienerveranstaltung = new Messdienerveranstaltung();
  selectedMessdienerveranstaltung!: Messdienerveranstaltung;
  displayDeleteButton: boolean = true;

  detailModal: any;
  deleteAlert: any;

  activeMessdieners: Messdiener[] = [];

  constructor(
    private _api: ApiService,
    public modalController: ModalController,
    private alertController: AlertController,
    public loadingService: LoadingService,
    public dateTimeFormatFilter: DateTimeFormatPipe) { }

  ngOnInit(): void {
    this.getActiveMessdiener();
    this.getMessdienerVeranstaltungen();
  }

  getMessdienerVeranstaltungen(){
    this.loadingService.present();
    this._api.getTypeRequest('messdienerveranstaltungen', '')?.subscribe((res: any) => {
      this.messdienerveranstaltungen = [];
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let messdienerveranstaltung: Messdienerveranstaltung = new Messdienerveranstaltung(element.id, null, element.name, element.start_date);
          this.messdienerveranstaltungen.push(messdienerveranstaltung);
          this.dataSource.data = this.messdienerveranstaltungen;
          this.table.renderRows();
        }
        this.loadingService.dismiss();
      } else {
        console.log(res.data);
        this.loadingService.dismiss();
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  open(messdienerveranstaltung: Messdienerveranstaltung) {
    this.backupThisMessdienerVeranstaltung(messdienerveranstaltung);
    this.selectedMessdienerveranstaltung = messdienerveranstaltung;
    this.presentModal();
  }

  private backupThisMessdienerVeranstaltung(messdienerveranstaltung: Messdienerveranstaltung) {
    this.backupMessdienerveranstaltung.name = messdienerveranstaltung.name;
    this.backupMessdienerveranstaltung.startDate = messdienerveranstaltung.startDate;
  }

  private restoreMessdienerVeranstaltung() {
    this.selectedMessdienerveranstaltung.name = this.backupMessdienerveranstaltung.name;
    this.selectedMessdienerveranstaltung.startDate = this.backupMessdienerveranstaltung.startDate;
  }
  
  async presentModal() {
    this.detailModal = await this.modalController.create({
      component: MessdienerveranstaltungDetailComponent,
      componentProps: {
        'messdienerveranstaltungComponent': this
      }
    });
    await this.detailModal.present()
    await this.detailModal.onDidDismiss().then((r) => {
      if (!r.data) {
        this.restoreMessdienerVeranstaltung();
      }

      this.displayDeleteButton = true;
    });
  }
  
  otherModalIsNull = true;
  @HostListener('window:popstate', ['$event'])
  dismissDetailAlert() {
    if (this.detailModal != null  && this.otherModalIsNull) {
      if (this.deleteAlert != null) {
        this.deleteAlert.dismiss();
        this.deleteAlert = null;
      } else {
        this.detailModal.dismiss();
        this.detailModal = null;
      }
    }
  }

  addMessdienerveranstaltung(){
    this.addButton._elementRef.nativeElement.blur();
    var messdienerveranstaltung = new Messdienerveranstaltung();
    messdienerveranstaltung.startDate = new Date();
    this.displayDeleteButton = false;
    this.open(messdienerveranstaltung);
  }

  saveMessdienerVeranstaltung(messdienerveranstaltung: Messdienerveranstaltung) {
    this.loadingService.present();
    var messdienerveranstaltungValues = { 
      "id": null,
      "name": messdienerveranstaltung.name,
      "start_date": this.toDateString(messdienerveranstaltung.startDate)
    };
    if (this.displayDeleteButton) {
      messdienerveranstaltungValues.id = messdienerveranstaltung.id;
    }
    this._api.postTypeRequest('messdienerveranstaltung', messdienerveranstaltungValues)?.subscribe((res: any) => {
      this.getMessdienerVeranstaltungen();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  public toDateString(date: Date): string {
    if(typeof(date) == 'string'){
      date = new Date(date);
    }
    return (date.getFullYear().toString() + '-' 
       + ("0" + (date.getMonth() + 1)).slice(-2) + '-' 
       + ("0" + (date.getDate())).slice(-2))
       + 'T' + date.toTimeString().slice(0,5);
  }

  async presentDeleteAlert() {
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + this.selectedMessdienerveranstaltung.name + " löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Löschen',
          handler: () => {
            this.loadingService.present();
            this._api.deleteTypeRequest('messdienerveranstaltung/' + this.selectedMessdienerveranstaltung.id + '/delete')?.subscribe((res: any) => {
              this.getMessdienerVeranstaltungen();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  getActiveMessdiener(){
    this._api.getTypeRequest('messdienergruppen/messdiener', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let person = new Person(element.personId, element.firstname, element.lastname);
          let messdiener: Messdiener = new Messdiener(element.id, person);
          this.activeMessdieners.push(messdiener);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }

}
