import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Leiter } from 'src/entities/leiter';
import { Messdiener } from 'src/entities/messdiener';
import { Messdienergruppe } from 'src/entities/messdienergruppe';
import { Person } from 'src/entities/person';
import { MessdienerGruppeDetailComponent } from '../messdiener-gruppe-detail/messdiener-gruppe-detail.component';

@Component({
  selector: 'app-messdiener-gruppe',
  templateUrl: './messdiener-gruppe.component.html',
  styleUrls: ['./messdiener-gruppe.component.scss'],
})
export class MessdienerGruppeComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<Messdienergruppe>;
  @ViewChild('addButton') addButton;
  dataSource = new MatTableDataSource<Messdienergruppe>();
  columnsToDisplay = ['messdienergruppe.name', 'messdienergruppe.year'];
  messdienergruppen: Messdienergruppe[] = [];
  selectedMessdienergruppe!: Messdienergruppe;
  backupMessdienergruppe: Messdienergruppe = new Messdienergruppe();
  gruppenCount: number = 0;
  currentPageSize: number = 10;
  currentPageIndex: number = 0;
  displayDeleteButton: boolean = true;

  activeMessdieners: Messdiener[] = [];
  activeLeiters: Leiter[] = [];

  activeToggle: Boolean = true;
  detailModal: any;
  deleteAlert:any;

  constructor(
    private _api: ApiService,
    public modalController: ModalController,
    private alertController: AlertController,
    public loadingService: LoadingService,
    private popover: PopoverController,
    public _auth: AuthService) { }

  ngOnInit() {
    this.loadingService.present();
    this.getActiveLeiter();
    this.getActiveMessdiener();
    this.getMessdienergruppen();
  }

  getMessdienerGruppenParamter(): Boolean{
    return this.activeToggle;
  }

  getActiveMessdiener(){
    this._api.getTypeRequest('messdienergruppen/messdiener', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let person = new Person(element.personId, element.firstname, element.lastname);
          let messdiener: Messdiener = new Messdiener(element.id, person);
          this.activeMessdieners.push(messdiener);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }

  getActiveLeiter(){
    this._api.getTypeRequest('messdienergruppen/leiter', '')?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let person = new Person(element.personId, element.firstname, element.lastname);
          let leiter: Leiter = new Leiter(element.id, person);
          this.activeLeiters.push(leiter);
        }
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }

  getMessdienergruppen(){
    this.messdienergruppen =  [];
    let parameter: string[] =
      [
        '&active='+ this.activeToggle,
        '&index=' + this.currentPageIndex,
        '&size=' + this.currentPageSize,
      ];
    this._api.getTypeRequest('messdienergruppen', parameter.join(""))?.subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          let messdienergruppe: Messdienergruppe = new Messdienergruppe(element.id, [], [], element.name, element.year, element.active);
          this.messdienergruppen.push(messdienergruppe);
        }
        this.gruppenCount = res.count;
        this.dataSource.data = this.messdienergruppen;
        this.table.renderRows();
        this.loadingService.dismiss();
      } else {
        this.loadingService.dismiss();
        console.log(res.data);
      }
    }, err => {
      this.loadingService.dismiss();
      console.log(err);
    });
  }

  public getServerData(event?: PageEvent) {
    this.currentPageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.getMessdienergruppen();
  }

  async presentModal() {
    this.detailModal = await this.modalController.create({
      component: MessdienerGruppeDetailComponent,
      componentProps: {
        'messdienergruppeComponent': this
      }
    });
    await this.detailModal.present();
    await this.detailModal.onDidDismiss().then((r) => {
      if (!r.data) {
        this.restoreMessdienergruppe();
      }

      this.displayDeleteButton = true;
    });
  }

  otherModalIsNull = true;
  @HostListener('window:popstate', ['$event'])
  dismissDetailAlert() {
    if (this.detailModal != null && this.otherModalIsNull) {
      if (this.deleteAlert != null) {
        this.deleteAlert.dismiss();
        this.deleteAlert = null;
      } else {
        this.detailModal.dismiss();
        this.detailModal = null;
      }
    }
  }

  async presentDeleteAlert() {
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + this.selectedMessdienergruppe.name + " " + this.selectedMessdienergruppe.year + " löschen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          cssClass: 'deleteButton',
          text: 'Löschen',
          handler: () => {
            this.loadingService.present();
            this._api.deleteTypeRequest('messdienergruppe/' + this.selectedMessdienergruppe.id + '/delete')?.subscribe((res: any) => {
              this.getMessdienergruppen();
              this.detailModal.dismiss();
              this.loadingService.dismiss();
            }, err => {
              this.loadingService.dismiss();
              console.log(err);
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  open(messdienergruppe: Messdienergruppe) {
    this.backupThisMessdienergruppe(messdienergruppe);
    this.selectedMessdienergruppe = messdienergruppe;
    this.presentModal();
  }

  private backupThisMessdienergruppe(messdienergruppe: Messdienergruppe) {
    this.backupMessdienergruppe.active = messdienergruppe.active;
    this.backupMessdienergruppe.name = messdienergruppe.name;
    this.backupMessdienergruppe.year = messdienergruppe.year;
  }

  private restoreMessdienergruppe() {
    this.selectedMessdienergruppe.active = this.backupMessdienergruppe.active;
    this.selectedMessdienergruppe.name = this.backupMessdienergruppe.name;
    this.selectedMessdienergruppe.year = this.backupMessdienergruppe.year;
  }

  addMessdienergruppe(){
    this.addButton._elementRef.nativeElement.blur();
    var messdienergruppe = new Messdienergruppe();
    messdienergruppe.active = true;
    this.displayDeleteButton = false;
    this.open(messdienergruppe);
  }

  saveMessdienergruppe(messdienergruppe: Messdienergruppe) {
    this.loadingService.present();
    var messdienerGruppeValues = { 
      "id": null,
      "name": messdienergruppe.name,
      "year": messdienergruppe.year,
      "active": messdienergruppe.active
    };
    if (this.displayDeleteButton) {
      messdienerGruppeValues.id = messdienergruppe.id;
    }
    this._api.postTypeRequest('messdienergruppe', messdienerGruppeValues)?.subscribe((res: any) => {
      this.getMessdienergruppen();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {messdienerGruppeComponent: this}
    });
    await popover.present();
  }
}
