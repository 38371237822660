import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Leiter } from 'src/entities/leiter';
import { Messdiener } from 'src/entities/messdiener';
import { Messdienergruppe } from 'src/entities/messdienergruppe';
import { Person } from 'src/entities/person';
import { MessdienerGruppeDetailAddComponent } from '../messdiener-gruppe-detail-add/messdiener-gruppe-detail-add.component';
import { MessdienerGruppeComponent } from '../messdiener-gruppe/messdiener-gruppe.component';

@Component({
  selector: 'app-messdiener-gruppe-detail',
  templateUrl: './messdiener-gruppe-detail.component.html',
  styleUrls: ['./messdiener-gruppe-detail.component.scss'],
})
export class MessdienerGruppeDetailComponent implements OnInit {
  @Input() messdienergruppeComponent: MessdienerGruppeComponent;
  @ViewChild('leiterAddButton') leiterAddButton;
  @ViewChild('messdienerAddButton') messdienerAddButton;
  @ViewChild('leiterTable') leiterTable!: MatTable<Leiter>;
  @ViewChild('messdienerTable') messdienerTable!: MatTable<Messdiener>;
  mobile: Boolean;

  addModal: any;
  deleteAlert: any;

  leiterDataSource = new MatTableDataSource<Leiter>();
  leiterColumnsToDisplay = ['leiter.firstname', 'leiter.lastname', 'leiter.delete'];

  messdienerDataSource = new MatTableDataSource<Messdiener>();
  messdienerColumnsToDisplay = ['messdiener.firstname', 'messdiener.lastname', 'messdiener.delete'];

  displayDeleteButton: true;

  constructor(
    public modalController: ModalController,
    private _api: ApiService,
    private alertController: AlertController,
    public loadingService: LoadingService,
    private popover:PopoverController,
    public _auth: AuthService) { }

  ngOnInit() {
    this.getMessdienerGruppeDetails();
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  getMessdienerGruppeDetails(){
    this.loadingService.present();
    this.messdienergruppeComponent.selectedMessdienergruppe.leiter = [];
    this.messdienergruppeComponent.selectedMessdienergruppe.messdiener = [];
    this._api.getTypeRequest('messdienergruppe/'+this.messdienergruppeComponent.selectedMessdienergruppe.id, "")?.subscribe((res: any) => {
      if (res.status) {
        const element = res.data;
        for (let index = 0; index < element.leiter.length; index++) {
          let person: Person = new Person(element.leiter[index].person.id, element.leiter[index].person.firstname, element.leiter[index].person.lastname);
          let leiter: Leiter = new Leiter(element.leiter[index].id, person);
          this.messdienergruppeComponent.selectedMessdienergruppe.leiter.push(leiter);
        }
        for (let index = 0; index < element.messdiener.length; index++) {
          let person: Person = new Person(element.messdiener[index].person.id, element.messdiener[index].person.firstname, element.messdiener[index].person.lastname);
          let messdiener: Messdiener = new Messdiener(element.messdiener[index].id, person);
          this.messdienergruppeComponent.selectedMessdienergruppe.messdiener.push(messdiener);
        }
        this.leiterDataSource.data = this.messdienergruppeComponent.selectedMessdienergruppe.leiter;
        this.messdienerDataSource.data = this.messdienergruppeComponent.selectedMessdienergruppe.messdiener;
        this.loadingService.dismiss();
      } else {
        console.log(res.data);
      }
    }, err => {
      console.log(err);
    });
  }

  addLeiterToMessdienerGruppe(leiterID: string){
    var body = { 
      "messdienergruppeId": this.messdienergruppeComponent.selectedMessdienergruppe.id,
      "leiterId": leiterID
    };
    this.loadingService.present();
    this._api.postTypeRequest('messdienergruppe/leiter/add', body)?.subscribe((res: any) => {
      var leiter = this.messdienergruppeComponent.activeLeiters.find(leiter => leiter.id == leiterID);
      this.messdienergruppeComponent.selectedMessdienergruppe.leiter.push(leiter);
      this.leiterTable.renderRows();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  addMessdienerToMessdienerGruppe(messdienerID: string){
    var body = { 
      "messdienergruppeId": this.messdienergruppeComponent.selectedMessdienergruppe.id,
      "messdienerId": messdienerID
    };
    this.loadingService.present();
    this._api.postTypeRequest('messdienergruppe/messdiener/add', body)?.subscribe((res: any) => {
      var messdiener = this.messdienergruppeComponent.activeMessdieners.find(messdiener => messdiener.id == messdienerID);
      this.messdienergruppeComponent.selectedMessdienergruppe.messdiener.push(messdiener);
      this.messdienerTable.renderRows();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  deleteSelectedMessdienergruppe() {
    this.messdienergruppeComponent.presentDeleteAlert();
  }

  saveSelectedMessdienergruppe(messdienergruppe: Messdienergruppe) {
    this.messdienergruppeComponent.saveMessdienergruppe(messdienergruppe);
    this.modalController.dismiss(true);
  }

  async presentAddModal(isLeiter: boolean) {
    if(isLeiter){
      this.leiterAddButton._elementRef.nativeElement.blur();
    }else{
      this.messdienerAddButton._elementRef.nativeElement.blur();
    }
    this.messdienergruppeComponent.otherModalIsNull = false;
    this.addModal = await this.modalController.create({
      component: MessdienerGruppeDetailAddComponent,
      componentProps: {
        'messdienergruppeDetailComponent': this,
        'isLeiter': isLeiter
      }
    });
    await this.addModal.present();
  }

  async presentLeiterDeleteAlert(leiter: Leiter) {
    this.messdienergruppeComponent.otherModalIsNull = false;
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + leiter.person.firstname + " " + leiter.person.lastname + "  von der Gruppe "+this.messdienergruppeComponent.selectedMessdienergruppe.name+" entfernen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Entfernen',
          handler: () => {
            this.loadingService.present();
            var body = { 
              "messdienergruppeId": this.messdienergruppeComponent.selectedMessdienergruppe.id,
              "leiterId": leiter.id
            };
            this._api.postTypeRequest('messdienergruppe/leiter/delete', body)?.subscribe((res: any) => {
              const index = this.messdienergruppeComponent.selectedMessdienergruppe.leiter.indexOf(leiter, 0);
              if (index > -1) {
                this.messdienergruppeComponent.selectedMessdienergruppe.leiter.splice(index, 1);
              }
              this.leiterTable.renderRows();
              this.loadingService.dismiss();
            }, err => {
              console.log(err);
              this.loadingService.dismiss();
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  async presentMessdienerDeleteAlert(messdiener: Messdiener) {
    this.messdienergruppeComponent.otherModalIsNull = false;
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + messdiener.person.firstname + " " + messdiener.person.lastname + "  von der Gruppe "+this.messdienergruppeComponent.selectedMessdienergruppe.name+" entfernen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Entfernen',
          handler: () => {
            this.loadingService.present();
            var body = { 
              "messdienergruppeId": this.messdienergruppeComponent.selectedMessdienergruppe.id,
              "messdienerId": messdiener.id
            };
            this._api.postTypeRequest('messdienergruppe/messdiener/delete', body)?.subscribe((res: any) => {
              const index = this.messdienergruppeComponent.selectedMessdienergruppe.messdiener.indexOf(messdiener, 0);
              if (index > -1) {
                this.messdienergruppeComponent.selectedMessdienergruppe.messdiener.splice(index, 1);
              }
              this.messdienerTable.renderRows();
              this.loadingService.dismiss();
            }, err => {
              console.log(err);
              this.loadingService.dismiss();
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }

  @HostListener('window:popstate', ['$event'])
  dismissAddAlert() {
    if (this.addModal != null) {
      this.addModal.dismiss();
      this.addModal = null;
      this.messdienergruppeComponent.otherModalIsNull = true;
    }
    if (this.deleteAlert != null) {
      this.deleteAlert.dismiss();
      this.deleteAlert = null;
      this.messdienergruppeComponent.otherModalIsNull = true;
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {messdienerGruppeDetailComponent: this}
    });
    await popover.present();
  }
}
