import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    baseUrl = 'https://api.vorstand.messdiener-lastrup.de/api/';
    //baseUrl = 'http://localhost:8000/api/';
    token  = '?X-Api-Token=0d0cc50b-5279-445c-84bc-0f11e2b2dd71';
    constructor(
        private _http: HttpClient,
        private _auth: AuthService,
        private router: Router) {
    }
    getTypeRequest(url: any, parameter: any = null) {
        if(!this.checkToken()){
            return;
        }
        return this._http.get(`${this.baseUrl}${url}${this.token}${parameter}`).pipe(map(res => {
            return res;
        }));
    }
    postTypeRequest(url: any, payload: any) {
        if(!this.checkToken() && url != "login"){
            return;
        }
        return this._http.post(`${this.baseUrl}${url}${this.token}`, payload).pipe(map(res => {
            return res;
        }));
    }
    deleteTypeRequest(url: any) {
        if(!this.checkToken() && url != "login"){
            return;
        }
        return this._http.delete(`${this.baseUrl}${url}${this.token}`).pipe(map(res => {
            return res;
        }));
    }
    putTypeRequest(url: any, payload: any) {
        if(!this.checkToken()){
            return;
        }
        return this._http.put(`${this.baseUrl}${url}${this.token}`, payload).pipe(map(res => {
            return res;
        }));
    }

    checkToken(){
        if(this._auth.getToken() == null || this._auth.isTokenExpired()){
            this._auth.clearStorage();
            this.router.navigate(['/login']);
            return false;
        }
        return true;
    }

    getLeiterPdfUrl(){
        return `${this.baseUrl}pdf/leiter${this.token}`;
    }

    getLeiterStrichePdfUrl(leiterId: string){
        return `${this.baseUrl}pdf/leiter/${leiterId}${this.token}`;
    }

    getAllLeiterStrichePdfUrl(){
        return `${this.baseUrl}pdf/leiter/striche${this.token}`;
    }

    getMessdienerPdfUrl(){
        return `${this.baseUrl}pdf/messdiener${this.token}`;
    }

    getMessdienerStrichePdfUrl(messdienerId: string){
        return `${this.baseUrl}pdf/messdiener/${messdienerId}${this.token}`;
    }

    getMessdienerGruppePdfUrl(messdienergruppeId: string){
        return `${this.baseUrl}pdf/messdienergruppe/${messdienergruppeId}${this.token}`;
    }

    getMessdienerGruppenPdfUrl(active: Boolean){
        return `${this.baseUrl}pdf/messdienergruppen${this.token}&active=${active}`;
    }

    getMessdienerVeranstaltungPdfUrl(messdienerveranstaltungId: string){
        return `${this.baseUrl}pdf/messdienerveranstaltung/${messdienerveranstaltungId}${this.token}`;
    }

    getLeiterVeranstaltungPdfUrl(leiterveranstaltungId: string){
        return `${this.baseUrl}pdf/leiterveranstaltung/${leiterveranstaltungId}${this.token}`;
    }
}