import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SettingsPopOverPage } from 'src/app/settings-pop-over/settings-pop-over.page';
import { Leiter } from 'src/entities/leiter';
import { Leiterveranstaltung } from 'src/entities/leiterveranstaltung';
import { Person } from 'src/entities/person';
import { LeiterveranstaltungDetailAddComponent } from '../leiterveranstaltung-detail-add/leiterveranstaltung-detail-add.component';
import { LeiterveranstaltungComponent } from '../leiterveranstaltung/leiterveranstaltung.component';

@Component({
  selector: 'app-leiterveranstaltung-detail',
  templateUrl: './leiterveranstaltung-detail.component.html',
  styleUrls: ['./leiterveranstaltung-detail.component.scss'],
})
export class LeiterveranstaltungDetailComponent implements OnInit {

  @ViewChild(MatTable) table!: MatTable<Leiter>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('leiterAddButton') leiterAddButton;
  @Input() leiterveranstaltungComponent: LeiterveranstaltungComponent;
  mobile: Boolean;
  dataSource = new MatTableDataSource<Leiter>();
  columnsToDisplay = ['leiter.firstname', 'leiter.lastname', 'leiter.delete'];
  leiters: Leiter[] = [];

  currentStartDate: String = "";
  dateCorrect = false;

  addModal: any;
  deleteAlert: any;

  constructor(
    private _api: ApiService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    private alertController: AlertController,
    private popover:PopoverController
  ) { }

  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);

    if(this.leiterveranstaltungComponent.selectedLeiterveranstaltung.id != null){
      this.loadingService.present();
      this.getLeiters();
    }

    try{
      var veranstaltungMoment = moment(this.leiterveranstaltungComponent.selectedLeiterveranstaltung.startDate);
      veranstaltungMoment.add(2, 'hours');
      this.currentStartDate = veranstaltungMoment.toISOString().slice(0,16);
      this.dateCorrect = true;
    }catch(e){
      this.dateCorrect = false;
    }
  }

  public startDateChanged(date: any){
    try{
      if(date == ""){
        this.dateCorrect = false;
      }else{
        this.leiterveranstaltungComponent.selectedLeiterveranstaltung.startDate = new Date(date);
        this.dateCorrect = true;
      }
    }catch(exception){
      this.dateCorrect = false;
    }
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  public getLeiters() {
    this._api.getTypeRequest('leiterveranstaltung/' + this.leiterveranstaltungComponent.selectedLeiterveranstaltung.id, "")?.subscribe((res: any) => {
      if (res.status) {
        this.leiters = [];
        for (let index = 0; index < res.data.leiter.length; index++) {
          const element = res.data.leiter[index];
          let person: Person = new Person();
          person.firstname = element.person.firstname;
          person.lastname = element.person.lastname;
          let leiter: Leiter = new Leiter(element.id, person);
          this.leiters.push(leiter);
        }
        this.dataSource.data = this.leiters;
        this.table.renderRows();
        this.loadingService.dismiss();
      } else {
        console.log(res);
        this.loadingService.dismiss();
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }
  
  deleteSelectedLeiterVeranstaltung(){
    this.leiterveranstaltungComponent.presentDeleteAlert();
  }

  saveSelectedLeiterVeranstaltung(leiterveranstaltung: Leiterveranstaltung){
    this.leiterveranstaltungComponent.saveLeiterVeranstaltung(leiterveranstaltung);
    this.modalController.dismiss(true);
  }

  async presentAddModal() {
    this.leiterAddButton._elementRef.nativeElement.blur();
    this.leiterveranstaltungComponent.otherModalIsNull = false;
    this.addModal = await this.modalController.create({
      component: LeiterveranstaltungDetailAddComponent,
      componentProps: {
        'leiterveranstaltungDetailComponent': this
      }
    });
    await this.addModal.present();
  }

  addLeiterToLeiterVeranstaltung(leiterID: string){
    var body = { 
      "veranstaltung_id": this.leiterveranstaltungComponent.selectedLeiterveranstaltung.id,
      "leiterId": leiterID
    };
    this.loadingService.present();
    this._api.postTypeRequest('leiterveranstaltung/leiter/add', body)?.subscribe((res: any) => {
      var leiter = this.leiterveranstaltungComponent.activeLeiters.find(leiter => leiter.id == leiterID);
      this.leiters.push(leiter);
      this.table.renderRows();
      this.loadingService.dismiss();
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
    });
  }

  async presentLeiterDeleteAlert(leiter: Leiter) {
    this.leiterveranstaltungComponent.otherModalIsNull = false;
    this.deleteAlert = await this.alertController.create({
      header: 'Löschen',
      message: "Willst du wirklich " + leiter.person.firstname + " " + leiter.person.lastname + "  von der Veranstaltung "+this.leiterveranstaltungComponent.selectedLeiterveranstaltung.name+" entfernen?",
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
        }, {
          text: 'Entfernen',
          handler: () => {
            this.loadingService.present();
            var body = { 
              "veranstaltung_id": this.leiterveranstaltungComponent.selectedLeiterveranstaltung.id,
              "leiterId": leiter.id
            };
            this._api.postTypeRequest('leiterveranstaltung/leiter/delete', body)?.subscribe((res: any) => {
              const index = this.leiters.indexOf(leiter, 0);
              if (index > -1) {
                this.leiters.splice(index, 1);
              }
              this.table.renderRows();
              this.loadingService.dismiss();
            }, err => {
              console.log(err);
              this.loadingService.dismiss();
            });
          }
        }
      ]
    });

    const modalState = {
      modal: true,
      desc: 'delete alert'
    };
    history.pushState(modalState, null);
    await this.deleteAlert.present();
  }


  @HostListener('window:popstate', ['$event'])
  dismissAddAlert() {
    if (this.addModal != null) {
      this.addModal.dismiss();
      this.addModal = null;
      this.leiterveranstaltungComponent.otherModalIsNull = true;
    }
    if (this.deleteAlert != null) {
      this.deleteAlert.dismiss();
      this.deleteAlert = null;
      this.leiterveranstaltungComponent.otherModalIsNull = true;
    }
  }

  async presentPopover(ev: any) {
    const popover = await this.popover.create({
      component: SettingsPopOverPage,
      event: ev,
      translucent: true,
      componentProps: {leiterveranstaltungDetailComponent: this}
    });
    await popover.present();
  }
}
