import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Leiter } from 'src/entities/leiter';
import { LeiterveranstaltungDetailComponent } from '../leiterveranstaltung-detail/leiterveranstaltung-detail.component';

@Component({
  selector: 'app-leiterveranstaltung-detail-add',
  templateUrl: './leiterveranstaltung-detail-add.component.html',
  styleUrls: ['./leiterveranstaltung-detail-add.component.scss'],
})
export class LeiterveranstaltungDetailAddComponent implements OnInit {

  @Input() leiterveranstaltungDetailComponent: LeiterveranstaltungDetailComponent;
  mobile: Boolean;

  leiters: Leiter[] = [];
  selectedId = null;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
    this.leiters= this.leiterveranstaltungDetailComponent.leiterveranstaltungComponent.activeLeiters
      .filter(leiter => !this.leiterveranstaltungDetailComponent.leiters.some(l => l.id == leiter.id));
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    const modalState = {
      modal: true,
      desc: 'detail modal'
    };
    history.pushState(modalState, null);
  }

  onValueChange(event: any){
    this.selectedId = event.detail.value;
  }

  addLeiter(){
    this.leiterveranstaltungDetailComponent.addLeiterToLeiterVeranstaltung(this.selectedId);
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (window.history.state.modal && !this.leiterveranstaltungDetailComponent.leiterveranstaltungComponent.otherModalIsNull) {
      history.back();
    }
  }
}
