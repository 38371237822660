import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from './../../../services/api.service'
import { AuthService } from './../../../services/auth.service'
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { LoadingService } from 'src/app/services/loading.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLogin: boolean = false
  errorMessage: any

  username: string;
  password: string;

  constructor(
    private _api: ApiService,
    private _auth: AuthService,
    private _router: Router,
    private storage: Storage,
    public loadingService: LoadingService
  ) { }
  async ngOnInit() {
    await this.storage.create();
    this.storage.get('username').then((result) => {
      this.username = result;
    });
    this.storage.get('password').then((result) => {
      this.password = result;
    });
    this.isUserLogin();
  }
  login() {
    this.loadingService.present();
    var loginValues = {"username": this.username, "password": this.password};
    this._api.postTypeRequest('login', loginValues)?.subscribe((res: any) => {
      if (res.status) {
        this.storage.set('username', this.username);
        this.storage.set('password', this.password);
        this._auth.setDataInLocalStorage('userData', JSON.stringify(res.data));
        this._auth.setDataInLocalStorage('token', res.token);
        localStorage.setItem("role", this._auth.decodeToken(res.token).role);
        this._auth.setAppPages();
        this._router.navigate(['']);
        this.loadingService.dismiss();
      } else {
        console.log(res.data);
        this.loadingService.dismiss();
        this.errorMessage = "Benutzername oder Passwort falsch!";
      }
    }, err => {
      console.log(err);
      this.loadingService.dismiss();
      this.errorMessage = err.message;
    });
  }
  isUserLogin() {
    if (this._auth.getUserDetails() != null) {
      this._auth.setAppPages();
      this.isLogin = true;
    }
  }
  logout() {
    this._auth.clearStorage()
    this._router.navigate(['']);
  }
}